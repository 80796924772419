type Subject = {
  kind: "journalist" | "other" | "general";
  amount?: number;
  who: string;
  gender?: string;
  employment_status?: string;
  type?: string;
};

export class Alert {
  id: number;
  title: string;
  content: string;
  date: string;
  has_date_of_incidence: boolean;
  loc: { lat: number; lng: number };
  regionNames: string[];
  country: string;
  type_of_incident: string[];
  type_of_incident_leaves: string[];
  source_of_incident: string[];
  who_was_attacked: string[];
  context_of_incident: string[];
  subjects: Subject[];
  type_of_journalist_or_media_actor: string[];
  specific_topic: string[];
  project: string[];
  gender: string[];
  employment_status: string[];
  support_needed: string[];
  attacked_count: number;
  consolidated_count: number;
  imageId?: number;
  videoId?: string;
  news_source_links: string;
  internet_source_links: string;
  verification_individual_count?: number = 0;

  coe_link: string;
  obct_labels: string[];

  constructor(rawJson: any) {
    this.id = rawJson.id;
    this.title = rawJson.title;
    this.loc = rawJson._geo;
    this.date = rawJson.date;
    this.has_date_of_incidence = rawJson.has_date_of_incidence;
    this.regionNames = rawJson.region_names;
    this.country = rawJson.region_names[1];
    this.type_of_incident = rawJson.type_of_incident;
    this.type_of_incident_leaves = rawJson.type_of_incident_leaves;
    this.source_of_incident = rawJson.source_of_incident;
    this.who_was_attacked = rawJson.who_was_attacked;
    this.context_of_incident = rawJson.context_of_incident;
    this.type_of_journalist_or_media_actor =
      rawJson.type_of_journalist_or_media_actor;
    this.specific_topic = rawJson.specific_topic;
    this.project = rawJson.project;
    this.gender = rawJson.gender;
    this.employment_status = rawJson.employment_status;
    this.subjects = rawJson.subjects;
    this.support_needed = rawJson.support_needed;
    this.attacked_count = rawJson.attacked_count;
    this.consolidated_count = rawJson.consolidated;
    this.content = rawJson.content;
    this.news_source_links = rawJson.news_source_links;
    this.internet_source_links = rawJson.internet_source_links;
    this.verification_individual_count = rawJson.verification_individual_count;
    this.imageId = rawJson.imageId;
    this.videoId = rawJson.videoId;
    this.coe_link = rawJson.coe_link;
    this.obct_labels = rawJson.obct_labels;
  }

  get ushahidi_url() {
    return `https://mappingmediafreedom.ushahidi.io/posts/${this.id}`;
  }

  get alert_url() {
    return `${document.location.origin}/alert/${this.id}`;
  }
  get share_url() {
    return this.alert_url;
  }

  get share_text() {
    return `Mapping media freedom incident: ${this.title}`;
  }
  get shortDate() {
    return this.date.substr(0, 10);
  }

  /* attacked count may be array or not and contain strings like "general_law" which are NaN and thus not greater 0 */
  get attacked_count_number() {
    if (Array.isArray(this.attacked_count)) {
      return this.attacked_count.find((item) => typeof item === "number");
    }
    return this.attacked_count;
  }
}

export class PreviewAlert {
  id: number;
  title: string;
  content: string;
  date: string;
  loc: { lat: number; lng: number };
  country: string;
  attacked_count: number;
  type_of_incident: string[];

  constructor(rawJson: any) {
    this.id = rawJson.id;
    this.title = rawJson.title || rawJson._formatted.title;
    this.loc = rawJson._geo || rawJson._formatted._geo;
    this.date = rawJson.date || rawJson._formatted.date;
    this.country =
      rawJson.region_names?.[1] || rawJson._formatted.region_names?.[1];
    this.attacked_count =
      rawJson.attacked_count || rawJson._formatted.attacked_count;
    this.type_of_incident =
      rawJson.type_of_incident_leaves ||
      rawJson._formatted.type_of_incident_leaves ||
      [];
    this.content = rawJson.content || rawJson._formatted.content;
  }

  get url() {
    return `${document.location.origin}/alert/${this.id}`;
  }
}
