import { Alert, PreviewAlert } from "./../state/alert";
import { makeAutoObservable, observable, reaction, runInAction } from "mobx";
import { MapDataDownloader } from "state/choropleth-regions";
import { MarkerGroup, Markers } from "state/explorer-markerclusters";
import { MeiliStore } from "state/explorer-search";
import { WindowStore } from "state/window";
import { VirtuosoHandle } from "react-virtuoso";
import * as qs from "qs";
import {
  euMembershipComparer,
  reverse,
  sortAlphabeticalAsc,
} from "utils/sorting";
import { debounceEffect } from "utils/debounceEffect";
import { post } from "@rails/request.js";

export class ExplorerStore {
  window = new WindowStore();

  filterPanelOpen = false;
  showList = this.window.isMd;
  showCharts = false;

  choroplethColorScheme = window.initData.choroplethColorScheme || "single";
  choroplethRepaintRequired = false;

  loggedIn: boolean = window.initData.logged_in;

  closeFilterPanel = () => {
    this.filterPanelOpen = false;
  };
  openFilterPanel = () => {
    this.filterPanelOpen = true;
  };

  showListToggle = () => {
    this.showList = !this.showList;
    if (this.showList) {
      this.showCharts = false;
      this.filterPanelOpen = false;
    }
  };
  showChartsToggle = () => {
    this.showCharts = !this.showCharts;
    if (this.showCharts) {
      this.showList = false;
      this.filterPanelOpen = false;
    }
  };
  bottomNavHomeClick = () => {
    this.showCharts = false;
    this.showList = false;
    this.closeFilterPanel();
  };
  filterPanelToggle = () => {
    this.filterPanelOpen = !this.filterPanelOpen;
    if (this.filterPanelOpen) {
      this.showList = false;
      this.showCharts = false;
    }
  };
  private hoveredRegionTimeout?: NodeJS.Timeout;

  get homeActive() {
    return !this.filterPanelOpen && !this.showCharts && !this.showList;
  }

  get filtersActive() {
    return (
      this.meili.filters?.size > 0 ||
      this.meili.dateOfIncidentFilter.hasFilter ||
      this.meili.dateOfPublicationFilter.hasFilter
    );
  }

  showFooterNav = window.innerWidth < 768;

  meili = new MeiliStore();
  mapData = new MapDataDownloader();
  markers = new Markers();
  mapFilterBounds: L.LatLngBounds | undefined = undefined;

  setFilterBounds = (bounds: L.LatLngBounds, zoom = 0) => {
    if (
      JSON.stringify(this.meili.geoFilterBounds) ===
        JSON.stringify([
          bounds.getNorth(),
          bounds.getWest(),
          bounds.getSouth(),
          bounds.getEast(),
        ]) &&
      this.meili.zoom === zoom
    ) {
      return;
    }

    this.mapFilterBounds = bounds;
    runInAction(() => {
      this.meili.setZoom(zoom);
      this.meili.setGeoFilterBounds(
        bounds.getNorth(),
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
      );
      this.meili.setMapCenter(bounds.getCenter().lat, bounds.getCenter().lng);
    });
  };

  markerGroup = new MarkerGroup({ click: (id) => this.setPost(id) });
  countryCounts = {};

  post?: Alert = undefined;
  hoveredPost?: PreviewAlert = undefined;
  hoveredRegionId?: number = undefined;
  hoveredRegion?: any = undefined;
  postStatusMessage = "";

  async setPost(id: number | undefined) {
    if (id) {
      try {
        const rawPost = await this.meili.loadById(id);
        const post = new Alert(rawPost);
        runInAction(() => {
          this.post = post;
          this.postStatusMessage = "";
          this.closeFilterPanel();
        });
      } catch {
        this.postStatusMessage = `No alert with id ${id} could be found`;
      }
    } else {
      runInAction(() => {
        this.postStatusMessage = "";
        this.post = undefined;
      });
    }
  }

  async setListHover(alert?: PreviewAlert) {
    if (alert) {
      this.hoveredPost = alert;
      return;
    }
    this.hoveredPost = undefined;
  }

  async setRegionHover(regionId?: number) {
    if (regionId) {
      this.hoveredRegionId = regionId;
      return;
    }
    this.hoveredRegionId = undefined;
  }

  previewPosts: PreviewAlert[] = [];

  updateStateFromUrl = (location: Location) => {
    const detailsMatch = location.pathname.match("/alert/(\\d+)");
    if (detailsMatch) {
      const detailsId = parseInt(detailsMatch[1], 10);
      this.setPost(detailsId);
    } else {
      this.setPost(undefined);
    }
    const params = qs.parse(location.search, {
      allowDots: true,
      ignoreQueryPrefix: true,
      comma: true,
    });
    if (params.q) {
      if (Array.isArray(params.q))
        this.meili.setCurrentQuery(params.q.join(","));
      else this.meili.setCurrentQuery(params.q.toString());
    }

    const and = params.and;
    if (and) {
      if (typeof and === "string") {
        this.meili.setAllFilterOperatorAnd([and]);
      } else {
        this.meili.setAllFilterOperatorAnd(and);
      }
    }

    const filters = Object.assign({}, params.f, params.filter);
    const availableFilters: { [key: string]: string[] } = {};
    (window.availableFilters = availableFilters),
      window.initData.tags.forEach((t) => {
        const dim = t.slug.replaceAll(/-/g, "_");
        t.children.forEach((c) => {
          availableFilters[dim] ||= [];
          availableFilters[dim].push(c.label);
          c.children?.forEach((c) => {
            availableFilters[dim].push(c.label);
            c.children?.forEach((c) => {
              availableFilters[dim].push(c.label);
              c.children?.forEach((c) => {
                availableFilters[dim].push(c.label);
              });
            });
          });
        });
      });

    const missingFilters: { [key: string]: string[] } = {};
    if (filters) {
      Object.keys(filters).forEach((dim) => {
        if (dim == "from" || dim == "min_date_of_incident") {
          this.meili.dateOfIncidentFilter.setMin(filters[dim]);
          return;
        }
        if (dim == "to" || dim == "max_date_of_incident") {
          this.meili.dateOfIncidentFilter.setMax(filters[dim]);
          return;
        }
        if (dim == "publication_from") {
          this.meili.dateOfPublicationFilter.setMin(filters[dim]);
          return;
        }
        if (dim == "publication_to") {
          this.meili.dateOfPublicationFilter.setMax(filters[dim]);
          return;
        }
        let values = filters[dim];
        if (!values.forEach) {
          values = [values];
        }
        if (dim == "eu_membership") {
          values = values.map((v) =>
            v
              .replace("European Union", "EU")
              .replace("member states", "Member States")
              .replace("states", "countries"),
          );
        }
        const fixedDimensions = [
          "year",
          "yyyymm",
          "country",
          "eu_membership",
          "id",
        ];
        const dynamicDimensions = window.initData.tags.map((t) =>
          t.slug.replaceAll(/-/g, "_"),
        );
        if (
          fixedDimensions.includes(dim) ||
          (dynamicDimensions.includes(dim) &&
            values.every((value: string) =>
              availableFilters[dim]?.includes(value),
            )) ||
          fixedDimensions
            .concat(dynamicDimensions)
            .map((dim) => `exclude_${dim}`)
            .includes(dim)
        ) {
          this.meili.addFilters(dim, values);
        } else if (
          dynamicDimensions.includes(dim) &&
          values.some((value: string) => availableFilters[dim]?.includes(value))
        ) {
          values.forEach((value: string) => {
            missingFilters[dim] ||= [];
            if (availableFilters[dim]?.includes(value)) {
              this.meili.addFilter(dim, value);
            } else {
              missingFilters[dim].push(value);
            }
          });
        } else {
          missingFilters[dim] = values;
        }
      });
    }
    if (!Object.keys(missingFilters).length) {
      console.log("no missing filters detected");
    }
    if (Object.keys(missingFilters).length) {
      console.log("missing filters:", missingFilters);
      // TODO: or force explicit lookup, e.g. by membership
      post("/redirects", {
        body: JSON.stringify({
          missing: missingFilters,
          all: filters,
          path: location.pathname,
          search: location.search,
        }),
      })
        .then((response) => {
          console.log("missing filters response", response);
          if (response.ok) {
            response.json.then(
              (result: {
                addFilters?: { [key: string]: string[] };
                removeFilters?: { [key: string]: string[] };
              }) => {
                console.log("missing filters response json then", result);
                if (result.addFilters) {
                  Object.keys(result.addFilters).forEach((dim) => {
                    this.meili.addFilters(dim, result.addFilters[dim]);
                  });
                }
                if (result.removeFilters) {
                  // TODO there shouldn't be for simple k-v redirects, but we should remove them from the URL
                  Object.keys(result.removeFilters).forEach((dim) => {
                    this.meili.removeFilters(dim, result.removeFilters[dim]);
                  });
                }
                // TODO: check still missing?
                // XXX: verify this.meili.UPDATEURL
              },
            );
          } else {
            console.log(
              "Error getting redirect information for missing filters.",
              response,
            );
          }
        })
        .catch((reason) => {
          console.log("Error getting redirect information", reason);
        });
    }
    if (params.sort) {
      this.meili.setCurrentSort(params.sort);
    }
    this.meili.initialized = true;
  };

  get urlPath() {
    return this.post ? `/alert/${this.post.id}` : `/explorer`;
  }

  get urlQueryObject() {
    return {
      q:
        this.meili.currentQuery.length == 0
          ? undefined
          : this.meili.currentQuery,
      f: {
        from: this.meili.dateOfIncidentFilter.minStr,
        to: this.meili.dateOfIncidentFilter.maxStr,
        publication_from: this.meili.dateOfPublicationFilter.minStr,
        publication_to: this.meili.dateOfPublicationFilter.maxStr,
        ...Object.fromEntries(this.meili.filters),
      },
      and: Array.from(this.meili.filterOperatorAnd).sort(),
      sort: this.meili.currentSortOption.value,
    };
  }
  get urlQuery() {
    return qs.stringify(this.urlQueryObject, {
      indices: false,
      allowDots: true,
      format: "RFC1738",
      encodeValuesOnly: true,
      arrayFormat: "comma",
      //comma: true,
    });
  }

  get pageUrl() {
    const url = new URL(window.location.href);
    url.pathname = this.urlPath;
    url.search = this.urlQuery;
    return url;
  }

  get pageTitle() {
    if (this.post)
      return `Mapping Media Freedom: ${this.post.country}: ${this.post.title} (${this.post.date})`;

    return "Mapping Media Freedom: Alerts Explorer";
  }

  virtuoso?: VirtuosoHandle = undefined;
  setVirtuoso(virtuoso: VirtuosoHandle) {
    this.virtuoso = virtuoso;
  }

  dimensionLabel(name: string) {
    switch (name) {
      case "year":
        return "Year of incident";
      case "country":
        return "Countries";
      case "eu_membership":
        return "EU membership status";
      case "top_type_of_incident":
        return "Main Type of incident";
      case "type_of_incident":
        return "Type of incident";
      case "source_of_incident":
        return "Source of incident";
      case "context_of_incident":
        return "Context of incident";
      case "who_was_attacked":
        return "Who was attacked";
      case "type_of_journalist_or_media_actor":
        return "Type of journalist or media actor";
      case "gender":
        return "Gender";
      case "employment_status":
        return "Employment status";
      case "specific_topic":
        return "Specific topic";
      case "yyyymm":
        return "Year and month of incident";
      case "yyyy":
        return "Year of incident";
      case "region_names":
        return "Region";
      case "date":
        return "Date of Incident";
      case "timestamp":
        return "Timestamp";
      case "_geo":
        return "Coordinates";
      case "attacked_count":
        return "Number of attacked persons or entities related to media";
      case "consolidated_count":
        return "Number of incidents / attacks consolidated in this alert";
      case "project":
        return "Project";
      case "id":
        return "ID";
      default:
        if (name.startsWith("exclude_"))
          return `Excluding ${this.dimensionLabel(
            name.substring("exclude_".length),
          )}`;
        return name;
    }
  }

  get filtersForUI() {
    const filters = [
      {
        id: "year",
        options: this.meili
          .facetDistributionWithDisjunctivelyCountedFieldsEntries("year")
          .sort(reverse(sortAlphabeticalAsc))
          .map(([value, count]) => ({
            value,
            label: value,
            count,
            checked: this.meili.hasFilter("year", value),
          })),
      },
      {
        id: "country",
        options: this.meili
          .facetDistributionWithDisjunctivelyCountedFieldsEntries("country")
          .map(([value, count]) => ({
            value,
            label: value,
            count,
            checked: this.meili.hasFilter("country", value),
          })),
      },
      {
        id: "eu_membership",
        options: this.meili
          .facetDistributionWithDisjunctivelyCountedFieldsEntries(
            "eu_membership",
          )
          .sort(euMembershipComparer)
          .map(([value, count]) => ({
            value,
            label: value,
            count,
            checked: this.meili.hasFilter("eu_membership", value),
          }))
          .reduce(
            (list, current) => {
              !list[list.length - 1].label.includes("date of incident") &&
                current.label.includes("date of incident") &&
                list.push({
                  value: "separator",
                  label: "Status on date of incident",
                  count: 0,
                  checked: false,
                });
              return [...list, current];
            },
            [
              {
                value: "separator",
                label: "Current status",
                count: 0,
                checked: false,
              },
            ],
          ),
      },
      {
        id: "type_of_incident",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "type_of_incident",
          "type-of-incident",
        ),
      },
      {
        id: "source_of_incident",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "source_of_incident",
          "source-of-incident",
        ),
      },
      {
        id: "context_of_incident",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "context_of_incident",
          "context-of-incident",
        ),
      },
      {
        id: "who_was_attacked",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "who_was_attacked",
          "who-was-attacked",
        ),
      },
      {
        id: "type_of_journalist_or_media_actor",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "type_of_journalist_or_media_actor",
          "type-of-journalist-or-media-actor",
        ),
      },
      {
        id: "gender",
        multiValue: true,
        options: this.meili.optionsForFilter("gender", "gender"),
      },
      {
        id: "employment_status",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "employment_status",
          "employment-status",
        ),
      },
      {
        id: "specific_topic",
        multiValue: true,
        options: this.meili.optionsForFilter(
          "specific_topic",
          "specific-topic",
        ),
      },
      {
        id: "project",
        multiValue: true,
        options: this.meili.optionsForFilter("project", "project"),
      },
    ];
    if (
      this.meili.filteredDimensions.includes("year") ||
      this.meili.filteredDimensions.includes("yyymm")
    ) {
      filters.splice(1, 0, {
        id: "yyyymm",
        options: this.meili
          .facetDistributionWithDisjunctivelyCountedFieldsEntries("yyyymm")
          .map(([value, count]) => ({
            value,
            label: value,
            count,
            checked: this.meili.hasFilter("yyyymm", value),
          })),
      });
    }
    return filters;
  }

  reload() {
    console.log("reloading in 1s");
    setTimeout(() => {
      this.meili.updateFacetCounts();
      this.meili.updatePreviewList();
      if (this.post) {
        this.setPost(this.post.id);
      }
      console.log("done reloading");
    }, 1000);
  }

  constructor() {
    makeAutoObservable(this, {
      previewPosts: observable.ref,
      choroplethColorScheme: false,
    });
    this.updateStateFromUrl(window.location);
    window.addEventListener("popstate", () => {
      this.updateStateFromUrl(window.location);
    });
    this.mapData.loadWorld();

    reaction(
      () => this.window.isMd,
      () => {
        this.showFooterNav = !this.window.isMd;
      },
    );
    reaction(
      () => this.meili.lastResponse,
      (response) => {
        if (response) {
          this.countryCounts = response.facetDistribution.region0_id;
        }
      },
    );
    reaction(
      () => this.meili.idLocRegionResponse,
      (response) => {
        if (response) {
          this.markers.markers = response.hits;
        }
      },
    );

    reaction(
      () => [this.meili.lastResponse, this.markers.markers],
      ([lastResponse, markers]) => {
        if (!lastResponse) return;
        if (!lastResponse.hits) {
          this.markerGroup.updateMarkers([]);
          return;
        }
        if (lastResponse.hits.length == this.markers.markers.length) {
          this.markerGroup.updateMarkers(markers);
        } else {
          const hitsIds = new Set(lastResponse.hits.map((hit) => hit.id));
          this.markerGroup.updateMarkers(
            markers.filter((m) => hitsIds.has(m.id)),
          );
        }
      },
      { delay: 0, fireImmediately: true },
    );
    reaction(
      () => this.meili.lastPreviewsResponse,
      (lastPreviewsResponse) => {
        // console.log("updating previews from response");
        runInAction(() => {
          this.previewPosts = lastPreviewsResponse.hits.map(
            (hit) => new PreviewAlert(hit),
          );
          this.virtuoso?.scrollTo({ top: 0 });
        });
      }, //, {delay: 100}
    );
    reaction(
      () => this.meili.lastPreviewsResponseLoadMoreHits,
      (lastPreviewsResponseLoadMore) => {
        if (lastPreviewsResponseLoadMore.length == 0) return;
        runInAction(() => {
          this.previewPosts = [
            ...this.previewPosts,
            ...lastPreviewsResponseLoadMore[
              lastPreviewsResponseLoadMore.length - 1
            ].hits.map((hit) => new PreviewAlert(hit)),
          ];
        });
      },
    );

    reaction(
      () => this.pageUrl,
      debounceEffect((url) => {
        if (window.location.href == url.href) {
          return;
        }

        runInAction(() => (this.postStatusMessage = ""));
        window.history.pushState({}, "", url);
      }, 200),
    );

    reaction(
      () => this.pageTitle,
      (title) => {
        document.title = title;
      },
    );

    reaction(
      () => this.hoveredRegionId,
      (regionId) => {
        //console.log("regionId", regionId)
        if (this.hoveredRegionTimeout) {
          clearTimeout(this.hoveredRegionTimeout);
        }
        if (regionId) {
          this.hoveredRegion =
            this.mapData.euMembershipRegions.find(
              (r) => r.osm_id == regionId,
            ) ?? this.mapData.regionData.find((r) => r.osm_id == regionId);
          this.hoveredRegionTimeout = setTimeout(() => {
            runInAction(() => {
              this.hoveredRegion = undefined;
            });
          }, 3000);
          //this.postStatusMessage = "";
        } else {
          this.hoveredRegion = undefined;
        }
      },
    );

    reaction(
      () => this.showList,
      (showList) => (this.meili.showPreviewList = showList),
      { fireImmediately: true },
    );
  }
}
