import { makeAutoObservable, observable, runInAction } from "mobx";

export class MapDataDownloader {
  euMembershipRegions: RegionFeature[] = euMembershipLabels().map(
    ([osm_id, name]) => new RegionFeature({ properties: { osm_id, name } }),
  );
  regionData: RegionFeature[] = [];
  constructor() {
    makeAutoObservable(this, {
      euMembershipRegions: observable.ref,
      regionData: observable.ref,
    });
  }
  loadWorld() {
    return fetch(`${window.initData.host}/world.geojson?v=1`)
      .then((response) => response.json())
      .then((json) => {
        runInAction(() => {
          this.regionData = RegionFeature.loadMany(json.features);
        });
      });
  }
}

export class RegionFeature {
  static loadMany(features: any[]): RegionFeature[] {
    return features.map((f) => new this(f));
  }
  osm_id: number;
  name: string;
  local_name: string;
  geojson: Feature;
  constructor(json: Feature) {
    this.geojson = json;
    this.name = json.properties.name; // XXX This is English, but we might want to add local names...
    this.local_name = json.properties.local_name;
    this.osm_id = json.properties.osm_id;
  }
}

function euMembershipLabels(): [number, string][] {
  return [
    [-1, "Unknown Location"],
    [-2, "European Union member states"],
    [-3, "European Union candidate states"],
    [-4, "European Union potential candidate states"],
    [-5, "Non-EU states"],
  ];
}
