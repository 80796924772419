import { useEffect } from "react";
import mmfLogo from "images/mmf-logo.svg";
import reportNewThreat from "images/report-new-threat.svg";
import { observer } from "mobx-react-lite";
import { ExternalLinkDuo } from "components/svgs";
import { ExplorerStore } from "explorer/store";

export const StandAloneLink = observer(function StandAloneLink({
  classNames,
  store,
}: {
  classNames?: string;
  store: ExplorerStore;
}) {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={store.pageUrl.href}
      class={`${classNames} pointer-events-auto z-aboveallcontentlogo w-9 rounded border-0 bg-white bg-opacity-75 p-2 shadow backdrop-blur-sm hover:bg-opacity-100 dark:bg-black dark:bg-opacity-75 dark:text-white`}
      title="Open as Alert Explorer standalone tab"
      target="_blank"
    >
      <ExternalLinkDuo className="h-full w-full" />
    </a>
  );
});

export const StandAloneHeader = observer(function StandaloneHeader() {
  useEffect(() => {
    document.documentElement.classList.add("with-standalone-header");
    return () => {
      document.documentElement.classList.remove("with-standalone-header");
    };
  });
  return (
    <div class="pointer-events-auto z-abovemap flex max-h-20 w-full flex-row justify-between bg-white bg-opacity-75 shadow-xl backdrop-blur-sm dark:bg-black  dark:bg-opacity-75">
      <a
        href="https://www.mappingmediafreedom.org/"
        target="_self"
        class="inline-block h-20 max-w-full lg:ml-10"
      >
        <img
          src={mmfLogo}
          alt="Mapping Media Freedom Alert Explorer"
          width="356"
          height="80"
          class="h-full max-w-full dark:brightness-125"
        />
      </a>
      <div class="right-0 hidden h-full md:flex">
        <a href="/report" target="_self" class="p-2 pr-4 align-middle">
          <img
            src={reportNewThreat}
            alt="Report new threat"
            width="240"
            height="59"
            class="w-60 dark:brightness-150"
          />
        </a>
      </div>
    </div>
  );
});
