import * as React from "react";
import { observer } from "mobx-react-lite";
import { FeatureGroup, GeoJSON, LayersControl, useMap } from "react-leaflet";
import { RegionFeature } from "state/choropleth-regions";
import { ExplorerStore } from "./store";
import { errorMessageWithReload } from "explorer/errors";
import { action } from "mobx";

export const ChoroplethRegions = observer(function ChoroplethRegions({
  store,
}: {
  store: ExplorerStore;
}) {
  if (
    store.choroplethColorScheme != "single" &&
    typeof store.countryCounts === "undefined"
  ) {
    errorMessageWithReload(
      "Alert Explorer is currently unable to load alerts.",
      "CountryCounts not available, probably reindex is in progress.",
    );

    return null;
  }
  return (
    <>
      <ChoroplethRegionLayer
        name="Countries"
        // checked={store.map.zoom < 6}
        checked={store.meili.zoom < 7}
        regions={store.mapData.regionData}
        counts={store.countryCounts}
        store={store}
      />
    </>
  );
});

function countForRegion(id: number, counts: { [id: number]: number }) {
  return counts[id] ?? 0;
}

function blend(start: number, end: number, percent: number) {
  return (1 - percent) * start + percent * end;
}

// https://www.dcode.fr/function-equation-finder
// 0.001953125	0.1
// 0.00390625	0.2
// 0.0078125	0.3
// 0.015625	0.4
// 0.03125	0.5
// 0.0625	0.6
// 0.125	0.7
// 0.25	0.8
// 0.5	0.9
// 1	1
// logarithmic -> 0.14427*Math.log(3.75803* linRatio)+0.809002
// more experiments: http://www.fooplot.com/#W3sidHlwZSI6MCwiZXEiOiJzaW4oeCoxLjUpIiwiY29sb3IiOiIjMDBGRjAwIn0seyJ0eXBlIjowLCJlcSI6IjEtKCgxLXgpKioyKSIsImNvbG9yIjoiIzAwRUVGRiJ9LHsidHlwZSI6MCwiZXEiOiIxLSgxLXgpKioyLjUiLCJjb2xvciI6IiMwMDAwMDAifSx7InR5cGUiOjAsImVxIjoiMS0oMS14KSoqMyIsImNvbG9yIjoiI0ZGMDAwMCJ9LHsidHlwZSI6MCwiZXEiOiIxLSgxLXgpKio1IiwiY29sb3IiOiIjMDAwMDAwIn0seyJ0eXBlIjowLCJlcSI6IjEtKDEteCkqKjYiLCJjb2xvciI6IiM4RjgyODIifSx7InR5cGUiOjAsImVxIjoiLSgyKiooLXgrMSkqKjUpKzIiLCJjb2xvciI6IiNERDAwRkYifSx7InR5cGUiOjAsImVxIjoiMC4xNDQyNypsbigzLjc1ODAzKngpKzAuODA5MDAyIiwiY29sb3IiOiIjQ0NGRjAwIn0seyJ0eXBlIjowLCJlcSI6IigyOS4xODU5Kih4KSoqMC4wMDQ1MTM5KS0yOC4xODU4IiwiY29sb3IiOiIjRkZBQTAwIn0seyJ0eXBlIjoxMDAwLCJ3aW5kb3ciOlsiMCIsIjEiLCIwIiwiMSJdfV0-
function colorForRegionGradient(
  id: number,
  counts: { [id: number]: number },
  maxCount: number,
) {
  const count = countForRegion(id, counts);
  if (count === 0) {
    return "#2C3E50";
  }
  const linRatio = count / maxCount;
  const ratio = 0.14427 * Math.log(3.75803 * linRatio) + 0.809002;
  if (ratio < 0.5) {
    const ratio2 = ratio * 2;
    const sat = blend(20, 0, ratio2);
    const lum = blend(37, 75, ratio2);
    return `hsl(210deg ${sat}% ${lum}%)`;
    //`hsl(210deg 20% 37%)` to `hsl(210deg 0% 75%)`
  }
  const ratio2 = (ratio - 0.5) * 2;
  const sat = blend(0, 81, ratio2);
  const lum = blend(75, 55, ratio2);
  return `hsl(7deg ${sat}% ${lum}%)`;
  //`hsl(7deg 0% 75%)` to `hsl(7deg 81% 55%)`
}

window.mapColors = {
  active: "#D86556",
  inactive: "#939393",
  border: "#2F3E4F",
  borderWidth: 0.5,
  water: "#394E63",
  theme: "single",
  markers: false,
};

function locationChangedColors() {
  const hash = document.location.hash;
  if (hash.length == 0) return;
  //console.log("Interpreting color definition", hash);
  window.defs = hash.slice(1).split(/[,&]/);
  window.defs.forEach((d) => {
    const pair = d.split("=");
    if (
      pair.length == 2 &&
      pair[0] &&
      pair[1] &&
      pair[0].length > 0 &&
      pair[1].length > 0 &&
      Object.keys(window.mapColors).includes(pair[0])
    ) {
      pair[1] = pair[1].replace(/^%23/i, "");
      if (pair[1].match(/^[0-9a-f]{6}$/i)) {
        pair[1] = `#${pair[1]}`;
      }
      console.log("Setting", pair[0], "to", pair[1]);

      if (pair[0] == "water") {
        document.body.style.backgroundColor = pair[1];
      } else if (pair[0] == "theme") {
        if (window.store) {
          window.store.choroplethColorScheme = pair[1];
        } else {
          window.initData.choroplethColorScheme = pair[1];
        }
      } else if (pair[0] == "markers") {
        if (pair[1] == "colored") {
          document.body.classList.remove("choropleth");
        }
      }
      window.mapColors[pair[0]] = pair[1];
      if (window.store) {
        action(() => (window.store.choroplethRepaintRequired = true))();
        setTimeout(
          action(() => (window.store.choroplethRepaintRequired = false)),
          0,
        );
      }
      //} else {
      //  console.log("Didn't understand color", d);
    }
  });
}
window.addEventListener("hashchange", locationChangedColors);
locationChangedColors();

function colorForRegionSingle(id: number) {
  //const count = countForRegion(id, counts);
  if (!window.initData.currentlyMonitoredCountries.includes(id)) {
    return window.mapColors.inactive;
  }
  return window.mapColors.active;
}

export const ChoroplethRegionLayer = observer(function ChoroplethRegionLayer({
  name,
  checked = true,
  regions,
  counts,
  store,
}: {
  name: string;
  checked: boolean;
  regions: RegionFeature[];
  counts: { [key: number]: number };
  store: ExplorerStore;
}) {
  const maxCount = Math.max(...Object.values(counts));
  const map = useMap();
  return (
    <LayersControl.Overlay checked={checked} name={name}>
      <FeatureGroup
        eventHandlers={{
          mouseover: (e) => {
            // FIXME: get store here in a clean way instead of using global
            store.setRegionHover(e.sourceTarget.feature.properties.osm_id);
          },
          click: (e) => {
            map.fitBounds(e.sourceTarget.getBounds(), {
              paddingTopLeft: [100, store.window.isStandalone ? 230 : 100],
              paddingBottomRight: [window.innerWidth / 3 + 100, 100],
              animate: true,
            });
          },
        }}
        attribution='<a target="_blank" href="https://osm-boundaries.com/">OSM-Boundaries</a>'
      >
        {regions.map((region) => (
          <ChoroplethRegion
            key={region.osm_id}
            region={region}
            //count={countForRegion(region.osm_id, counts, maxCount)}
            fillColor={
              store.choroplethColorScheme == "single"
                ? colorForRegionSingle(region.osm_id)
                : colorForRegionGradient(region.osm_id, counts, maxCount)
            }
          />
        ))}
      </FeatureGroup>
    </LayersControl.Overlay>
  );
});

export const ChoroplethRegion = observer(function ChoroplethRegion({
  region,
  pathOptionsOverride,
  fillColor,
}: {
  region: RegionFeature;
  pathOptionsOverride?: L.PathOptions;
  fillColor: string;
  //count: number;
}) {
  return (
    <>
      <GeoJSON
        data={region.geojson}
        pathOptions={{
          weight: window.mapColors.borderWidth,
          color: window.mapColors.border,
          fillColor,
          opacity: 1,
          // fillOpacity: count > 0 ? 0.8 : 0.4,
          fillOpacity: 1,
          ...pathOptionsOverride,
        }}
      />
    </>
  );
});
