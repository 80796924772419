import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";
import { UsersDuo } from "components/svgs";
import toast from "react-hot-toast";

const AttackedCountSummary = observer(function AttackedCountSummary({
  store,
}: {
  store: ExplorerStore;
}) {
  if (!store.meili.facetDistribution?.["attacked_count"]) {
    return null;
  }
  if (store.meili.facetDistribution["attacked_count"]["0"] !== undefined) {
    return null;
  }

  const attacked_count = Object.entries(
    store.meili.facetDistribution["attacked_count"],
  ).reduce((acc, [a, b]) => {
    // "0" Should not happen, because we don't display the component in this case
    if (a === "0") {
      return acc;
    }

    // this catches count-exclusions like "general_law"
    const num = Number(a);
    if (isNaN(num)) {
      return acc;
    }

    // the actual result
    return acc + num * b;
  }, 0);

  return (
    <span
      title="Number of attacked persons or entities related to media. Please be aware that this number is only available for alerts from 2020."
      onClick={(e) => toast(e.currentTarget.title)}
    >
      &nbsp;
      <UsersDuo className="inline h-4" />
      &thinsp;
      {attacked_count}
    </span>
  );
});
export const ResultSummary = observer(function ResultSummary({
  store,
}: {
  store: ExplorerStore;
}) {
  if (!store.meili.lastResponse) return <span>&nbsp;</span>; // not null to prevent layout shifting

  const summaryMessage = null;
  return (
    <>
      {store.postStatusMessage && (
        <div class="text-center">{store.postStatusMessage}</div>
      )}
      <div class="">
        {store.meili.lastResponse.estimatedTotalHits > 0 ? (
          <span>
            {store.meili.lastResponse.estimatedTotalHits} alerts{" "}
            {store.showList && //showList is needed because otherwise we don't load PreviewResponse https://trello.com/c/RKI9wDTY
              store.meili.lastResponse.estimatedTotalHits !=
                store.meili.lastPreviewsResponse?.estimatedTotalHits &&
              `(${store.meili.lastPreviewsResponse?.estimatedTotalHits} currently visible in area outlined dashed-red)`}
            {summaryMessage && <span>with {summaryMessage} applied</span>}
            <AttackedCountSummary store={store} />
          </span>
        ) : (
          <span>No results for current search/filter</span>
        )}
        {/*{" "}
        {store.filtersActive && (
          <button
            onClick={() => store.meili.clearFilters()}
            class={
              "float-right ml-0.5 inline-flex flex-shrink-0 items-center justify-center rounded-full py-0.5 pl-1 pr-2 text-steel-blue-400 hover:bg-gray-200 hover:bg-opacity-50 focus:bg-curious-blue-500 focus:text-white focus:outline-none group-hover:bg-curious-blue-300 group-hover:text-curious-blue-500 dark:hover:bg-gray-600"
            }
          >
            <TrashIcon class="inline-block h-4" />
            Clear filters
          </button>
        )}*/}
      </div>
    </>
  );
});
