import { observer } from "mobx-react-lite";
import { DateRangeFilter } from "state/date-range-filter";
import { runInAction } from "mobx";
import { XIcon } from "@heroicons/react/outline";
import {
  SelectedDimensionHeader,
  SelectedTag,
} from "components/filter-selected";

export const ActiveDateRangeFilter = observer(function ActiveDateRangeFilter({
  filter,
  label,
}: {
  filter: DateRangeFilter;
  label: string;
}) {
  if (!filter.hasFilter) return null;

  return (
    <p key={filter.field} class="mt-1">
      <SelectedDimensionHeader
        onClick={() => {
          filter.clear();
        }}
        title={`Clear filter for ${label}`}
      >
        {label}
      </SelectedDimensionHeader>{" "}
      {filter.min && (
        <SelectedTag
          onClick={() => filter.clearMin()}
          key={`min_${filter.field}`}
          extraClassName={"-ml-0.5"}
        >
          from {filter.minStr}
        </SelectedTag>
      )}
      {filter.max && (
        <SelectedTag
          onClick={() => filter.clearMax()}
          key={`max_${filter.field}`}
          extraClassName={"-ml-0.5"}
        >
          to {filter.maxStr}
        </SelectedTag>
      )}
    </p>
  );
});

export const FilterDateRange = observer(function FilterDateRange({
  filter,
  label,
  facets,
  extraClasses,
}: {
  filter: DateRangeFilter;
  label: string;
  facets: { [key: string]: number };
  extraClasses?: string;
}) {
  const pseudoFacets = {
    "2014-01-01": 0,
    [`${new Date().getFullYear() + 1}-12-31`]: 0,
  };
  const dates = Object.keys(facets || pseudoFacets).sort((a, b) =>
    a.localeCompare(b),
  );
  const minMin = filter.min ? "2014-01-01" : dates[0];
  const minMax = dates[dates.length - 1];
  const maxMin = dates[0];
  const maxMax = filter.max
    ? `${new Date().getFullYear() + 1}-12-31`
    : dates[dates.length - 1];

  return (
    <div
      class={`border-b border-t border-gray-200 py-4 font-medium text-gray-900 text-sm dark:text-gray-200 ${extraClasses || ""}`}
    >
      <div class={"flex justify-between"}>
        <span class={"inline-block"}>{label} between</span>

        {filter.hasFilter && (
          <button
            aria-label="Remove filter"
            class={`ml-1 h-6 w-6 rounded  border-0 bg-white bg-opacity-50 p-1 text-gray-600 dark:bg-black  dark:bg-opacity-70 dark:text-white`}
            onClick={(e) => {
              e.preventDefault();
              runInAction(() => filter.clear());
            }}
            title="Remove filter"
          >
            <XIcon class="h-full w-full" />
          </button>
        )}
      </div>
      <div class="mt-2 flex flex-1 flex-col justify-between sm:flex-row">
        <input
          type="date"
          name="min"
          value={filter.minStr || ""}
          onChange={(e) => filter.setMin(e.target.value)}
          min={minMin}
          max={minMax}
          class="form-input text-black"
        />{" "}
        <input
          type="date"
          name="max"
          value={filter.maxStr || ""}
          onChange={(e) => filter.setMax(e.target.value)}
          min={maxMin}
          max={maxMax}
          class="form-input text-black"
        />
      </div>
    </div>
  );
});
