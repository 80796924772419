import React from "react";

import { observer } from "mobx-react-lite";
import { marked } from "marked";

export const MarkdownText = observer(function MarkdownText({
  text,
  extraClasses = "",
}: {
  text: string;
  extraClasses?: string;
}) {
  if (!text) <></>;
  return (
    <div
      class={`prose max-w-full dark:!prose-invert ${extraClasses}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: marked
          .parse(text)
          .replaceAll(
            /<a(?! href="(https:\/\/www\.mapmf\.org\/|http:\/\/localhost))/g,
            "<a target='_blank' rel='noopener noreferrer'",
          ),
      }}
    />
  );
});
