import { makeAutoObservable } from "mobx";

export class WindowStore {
  isStandalone = window === window.parent;
  mdMediaQuery = window.matchMedia("(min-width: 768px)");

  isMd = this.mdMediaQuery.matches;

  setMd(val: boolean) {
    this.isMd = val;
  }

  constructor() {
    makeAutoObservable(this);

    try {
      this.mdMediaQuery.addEventListener("change", (e) =>
        this.setMd(e.matches),
      );
    } catch (e) {
      window.addEventListener("resize", () => {
        this.setMd(window.innerWidth >= 768);
      });
    }
  }
}

if (window === window.parent) {
  document.documentElement.classList.add("window-standalone");
} else {
  document.documentElement.classList.add("window-embedded");
}
