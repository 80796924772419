import { makeAutoObservable, runInAction } from "mobx";

class DynLoaded {
  Chart: any = undefined;
  constructor() {
    makeAutoObservable(this);
  }
}
export const dynLoaded = new DynLoaded();
async function loadChart() {
  const { SimpleLineChart } = await import("./explorer-charts-inner");
  runInAction(() => {
    dynLoaded.Chart = SimpleLineChart;
  });
  //console.log("chart loaded");
}

setTimeout(() => {
  loadChart();
}, 100);
