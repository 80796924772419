/* When running full-body React with render(<App/>, document.body),
 this makes the stream sources available which would otherwise be deleted */

import * as React from "react";

const streamSources: Element[] = [];
document
  .querySelectorAll("turbo-cable-stream-source")
  .forEach((e) => streamSources.push(e));

export function TurboStreamSources() {
  return (
    <div
      class="hidden"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: streamSources.map((e) => e.outerHTML).join(""),
      }}
    />
  );
}
