import "utils/airbrake";
import "utils/ahoy";
import "utils/polyfills";
import "preact/debug";
import "utils/rails-stuff";

import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";
import { ExplorerMap } from "explorer/map";
import { StandAloneHeader, StandAloneLink } from "components/standalone-header";
import { ChoroplethRegions } from "explorer/choropleth";
import { Clusters } from "components/explorer-markercluster";
import { ExplorerDetailsView } from "components/explorer-detailsview";
import { AlertList } from "explorer/alert-list";
import { FilterBar } from "components/filter-bar";
import { HoverMarker } from "components/explorer-hovermarker";
import { Toaster } from "react-hot-toast";
import { BottomNav } from "components/BottomNav";
import { MapControl } from "components/map-control";
import { CopyToClipboardButton } from "components/copy-to-clipboard-button";
import {
  HelpButton,
  ToggleChartsButton,
  ToggleListButton,
} from "components/menu-buttons";
import { showHelp } from "explorer/help";
import { dynLoaded } from "explorer/explorer-charts";
import { GeofilterProvider } from "components/geofilter-provider";
import { RegionPreview } from "components/region-hover";
import { errorMessageWithReload } from "explorer/errors";
import { LayerGroup } from "react-leaflet";
import { CopyFacetDataButton } from "components/copy-facet-data-button";
import { TurboStreamSources } from "utils/turbo-stream-sources";
import { createRoot } from "react-dom/client";

const explorerStore = new ExplorerStore();
(window as any).store = explorerStore;

const ExplorerApp = observer(function ExplorerApp() {
  const contentHeightClass = explorerStore.window.isStandalone
    ? "max-h-[calc(100%-136px)] md:max-h-[calc(100%-80px)]"
    : "max-h-[calc(100%-56px)] md:max-h-full";
  return (
    <>
      <Toaster reverseOrder={true} />
      <ExplorerMap standalone={explorerStore.window.isStandalone}>
        {!explorerStore.choroplethRepaintRequired && (
          <ChoroplethRegions store={explorerStore} />
        )}
        <Clusters store={explorerStore} />
        <HoverMarker store={explorerStore} />
        {window.initData.meiliIndex !== "alerts" && (
          <LayerGroup attribution={`Old Data`} />
        )}
        <MapControl
          position="topleft"
          classNamesInner={
            "flex flex-col space-y-2 text-curious-blue-500 dark:text-curious-blue-500"
          }
        >
          <HelpButton
            classNames="button"
            label={false}
            onClick={() => showHelp(explorerStore)}
          />
          {!explorerStore.window.isStandalone && (
            <StandAloneLink classNames="button" store={explorerStore} />
          )}
          <CopyToClipboardButton classNames={`button`} />
          <CopyFacetDataButton classNames={`button`} store={explorerStore} />
          {explorerStore.window.isMd && (
            <>
              <ToggleListButton
                classNames="button"
                label={false}
                onClick={() => explorerStore.showListToggle()}
              />
              <ToggleChartsButton
                classNames="button"
                label={false}
                onClick={() => explorerStore.showChartsToggle()}
              />
            </>
          )}
        </MapControl>
        <GeofilterProvider
          setFilterBounds={explorerStore.setFilterBounds}
          padding={[
            /* top right bottom left -- specific values more or less experimental*/
            explorerStore.window.isStandalone ? 80 : 0,
            explorerStore.window.isMd &&
            (explorerStore.showList || explorerStore.showCharts)
              ? window.innerWidth / 3 - (explorerStore.showCharts ? -20 : 20)
              : 0,
            explorerStore.window.isMd ? 0 : 55,
            0,
          ]}
          showRectangle={explorerStore.meili.isPreviewListGeofiltered}
        />
      </ExplorerMap>
      <div class="pointer-events-none relative z-abovemap flex h-full flex-1 flex-col">
        {explorerStore.window.isStandalone && <StandAloneHeader />}
        <div
          class={`flex flex-1 flex-col pl-14 md:flex-row ${contentHeightClass}`}
        >
          <div class="mx-auto flex max-h-full w-full flex-col md:flex-1 ">
            <FilterBar store={explorerStore} />

            <RegionPreview store={explorerStore} />
          </div>
          {explorerStore.showList && (
            <div class="list-content pointer-events-auto z-abovemap flex w-full flex-1 flex-col md:max-w-1/3">
              <AlertList
                className=""
                store={explorerStore}
                posts={explorerStore.previewPosts}
                openFull={(id) => void explorerStore.setPost(id)}
                hover={(alert) => explorerStore.setListHover(alert)}
              />
            </div>
          )}
          {explorerStore.showCharts && (
            <div class="chart-content pointer-events-auto z-abovemap grid max-h-[calc(100%-98px)] w-full grid-cols-1 grid-rows-[1fr] md:max-h-full md:max-w-1/3">
              {dynLoaded.Chart ? (
                <dynLoaded.Chart store={explorerStore} />
              ) : (
                <div>Loading Chart Library</div>
              )}
            </div>
          )}
        </div>
        {explorerStore.showFooterNav && (
          <div class={"pointer-events-auto z-abovemap"}>
            <BottomNav
              homeActive={explorerStore.homeActive}
              onHomeClick={explorerStore.bottomNavHomeClick}
              filterActive={explorerStore.filterPanelOpen}
              onFilterClick={explorerStore.filterPanelToggle}
              chartActive={explorerStore.showCharts}
              onChartClick={explorerStore.showChartsToggle}
              listActive={explorerStore.showList}
              onListClick={explorerStore.showListToggle}
              moreActive={false}
              onMoreClick={() => {
                /* not implemented yet */
              }}
            />
          </div>
        )}
        <ExplorerDetailsView store={explorerStore} />
        <TurboStreamSources />
      </div>
    </>
  );
});

const root = createRoot(document.body);
root.render(<ExplorerApp />);

if (!window.initData.meiliKey) {
  errorMessageWithReload(
    "Alert Explorer is currently unable to load alerts.",
    "meiliKey missing. Check if meilisearch is running.",
    window.initData.meiliKey,
  );
}

document.documentElement.classList.add("js-complete");
