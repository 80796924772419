import { makeAutoObservable, observable } from "mobx";
import * as L from "leaflet";
import {
  MarkerClusterGroup,
  markerClusterGroup,
} from "../regionbound/leaflet.markercluster-regionbound.min.js";

type Marker = {
  id: number;
  regions: number[];
  _geo: { lat: number; lng: number };
};

export class Markers {
  markers: Marker[];
  constructor() {
    this.markers = [];
    makeAutoObservable(this, {
      markers: observable.ref,
    });
  }
}

export class MarkerGroup {
  // zoom level: [0  1  2  3  4  5  6  7  8;
  regionLevels = [0, 0, 0, 0, 1, 1, 2, 2, 3];
  //regionLevels = [0, 1, 1, 2, 2, 3];

  clusterOptions: L.MarkerClusterGroupOptions = {
    //addRegionToolTips: "without subregions",
    allowClustersOfOne: true,
    maxClusterRadius: (zoom) => (zoom < 9 ? 99999 : 100),
    regionLevels: this.regionLevels,
    showCoverageOnHover: false,
    attribution: '<a href="https://regionbound.com/">RegionBound</a>',
  };
  marker_group: MarkerClusterGroup = markerClusterGroup(this.clusterOptions);

  updateMarkers(markers: Marker[]) {
    this.marker_group.clearLayers();
    this.marker_group.addLayers(
      markers.map((marker) =>
        L.marker(marker._geo, { id: marker.id, regions: marker.region_ids }),
      ),
    );
  }

  constructor({ click }: { click: (id: number) => void }) {
    this.marker_group.on("click", (e) => click(e.sourceTarget?.options?.id));
    this.marker_group.on("clusterclick", (e) => {
      (window as any).store.setRegionHover(e.sourceTarget?.getRegion());
      //console.log("clusterclick",e, e.sourceTarget);
    });
    this.marker_group.on("clustermouseover", (e) => {
      (window as any).store.setRegionHover(e.sourceTarget?.getRegion());
      //console.log("clustermouseover",e, e.sourceTarget);
    });
    this.marker_group.on("clustermouseout", (_e) => {
      //console.log("clustermouseout", e);
    });
  }
}
