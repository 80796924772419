import * as React from "react";
import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";
import { Marker, useMap } from "react-leaflet";

import L from "leaflet";

import hoverMarkerIconSvg from "images/map-icon-hover.svg";

const hoverMarkerIcon = new L.Icon({
  iconUrl: hoverMarkerIconSvg,
  iconRetinaUrl: hoverMarkerIconSvg,
  iconAnchor: new L.Point(18, 40),
  shadowUrl: undefined,
  iconSize: new L.Point(38, 38),
});

function panMapToMarker(
  map: L.Map,
  location: { lat: number; lng: number },
  store: ExplorerStore,
) {
  map.panInside(location, {
    paddingTopLeft: [100, store.window.isStandalone ? 230 : 100],
    paddingBottomRight: [window.innerWidth / 3 + 100, 100],
    animate: true,
  });
}

let timeOut: NodeJS.Timeout | undefined;
function delayedPanMapToMarker(
  map: L.Map,
  location: { lat: number; lng: number },
  store: ExplorerStore,
) {
  if (timeOut) {
    clearTimeout(timeOut);
  }
  timeOut = setTimeout(
    () => {
      panMapToMarker(map, location, store);
    },
    (map.getZoom() - map.getMinZoom()) * 500,
  );
}

export const HoverMarker = observer(function HoverMarker({
  store,
}: {
  store: ExplorerStore;
}) {
  const map = useMap();
  if (!store.hoveredPost) {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    return null;
  }
  if (!store.meili.isPreviewListGeofiltered)
    delayedPanMapToMarker(map, store.hoveredPost.loc, store);
  return <Marker position={store.hoveredPost.loc} icon={hoverMarkerIcon} />;
});
