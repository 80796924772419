import { observer } from "mobx-react-lite";
import { Alert, PreviewAlert } from "state/alert";
import { SharingButtons } from "posts-parts/sharing-buttons";
import { MiniMap } from "./minimap";
import { uniq } from "lodash";
import { MarkdownText } from "./markdown";
import { HomeIcon, LinkIcon } from "@heroicons/react/outline";
import copy from "copy-text-to-clipboard";
import toast from "react-hot-toast";
import { ExplorerStore } from "explorer/store";
import { NestedTags, Tag } from "components/nested-tags";

export const ExplorerDetailsView = observer(function ExplorerDetailsView({
  store,
}: {
  store: ExplorerStore;
}) {
  if (!store.post) {
    return null;
  }
  const previewIds = store.previewPosts.map((p) => p.id);
  const positionInPreviews = previewIds.indexOf(store.post.id);
  let prevPreview;
  let nextPreview;
  if (positionInPreviews !== -1) {
    prevPreview = store.previewPosts[positionInPreviews - 1];
    nextPreview = store.previewPosts[positionInPreviews + 1];
  }
  if (
    positionInPreviews >= store.previewPosts.length - 1 &&
    store.previewPosts.length <
      (store.meili.lastPreviewsResponse?.estimatedTotalHits ?? 0)
  ) {
    store.meili.updatePreviewList(store.previewPosts.length);
  }
  return (
    <DetailsView
      post={store.post}
      className="pointer-events-auto"
      closeFunction={() => store.setPost(undefined)}
      previousPost={prevPreview}
      nextPost={nextPreview}
      position={positionInPreviews}
      total={store.meili.lastPreviewsResponse?.estimatedTotalHits}
      store={store}
    />
  );
});

function removeHighlight(text: string) {
  return text?.replaceAll(/<\/?em>/g, "");
}

export const MiniPreview = observer(function MiniPreview({
  post,
}: {
  post: PreviewAlert;
}) {
  return (
    <span class={"truncate text-ellipsis whitespace-nowrap text-xs"}>
      {removeHighlight(post.country)}:{" "}
      {removeHighlight(post.title).substring(0, 100)}
    </span>
  );
});

const obctAvailableCountries = [
  "Albania",
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Luxembourg",
  "Malta",
  "Moldova",
  "Montenegro",
  "Netherlands",
  "North Macedonia",
  "Poland",
  "Portugal",
  "Romania",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Turkey",
  "Ukraine",
  "United Kingdom",
];

function ExternalResourceLinks(props: { post: Alert }) {
  const { post } = props;
  return (
    <div>
      <h3 class="mt-8 font-bold text-lg only:hidden">Additional Information</h3>
      <ObctResourceLink
        country={post.regionNames?.[1]}
        labels={post.obct_labels}
      />
      {post.coe_link?.length && (
        <p class="mt-2">
          <a
            href={post.coe_link}
            target="_blank"
            rel="noreferrer"
            class="underline"
          >
            Council of Europe safety of journalists platform alert
          </a>
        </p>
      )}
    </div>
  );
}

function ObctResourceLink(props: { country: string; labels: string[] }) {
  const { country, labels } = props;
  const items: { label: string; slug: string }[] = [];
  if (country) {
    const mappedCountry = country.replace("Czechia", "Czech Republic");
    const encodedCountry = encodeURIComponent(mappedCountry.toLowerCase());
    if (obctAvailableCountries.includes(mappedCountry)) {
      items.push({ label: mappedCountry, slug: encodedCountry });
    }
  }
  if (labels && labels.length > 0) {
    labels.forEach((label) =>
      items.push({
        label,
        slug: encodeURIComponent(label.toLowerCase().replaceAll(" ", "_")),
      }),
    );
  }
  if (items.length < 1) {
    return;
  }

  return (
    <>
      <p class="mt-2">
        The OBCT Resource Centre has more information about{" "}
        {items.map((item, idx) => [
          idx > 0 && (idx == items.length - 1 ? " and " : ", "),
          <a
            key={item.slug}
            href={`https://www.rcmediafreedom.eu/content/search?SearchText=&filter[]=attr_enhanced_tags_lk:${item.slug}&activeFacets[attr_enhanced_tags_lk:Tags]=${item.slug}`}
            target="_blank"
            rel="noreferrer"
            class="underline"
          >
            {item.label}
          </a>,
        ])}
        .
      </p>
    </>
  );
}

export const LegacySubjectsView = observer(function LegacySubjectsView({
  post,
}: {
  post: Alert;
}) {
  return (
    <div>
      {post.who_was_attacked?.length > 0 && (
        <>
          <h4 class="mt-4 font-bold">Who was attacked</h4>
          <NestedTags topSlug="who-was-attacked" tags={post.who_was_attacked} />
        </>
      )}
      {post.type_of_journalist_or_media_actor?.length && (
        <>
          <h4 class="mt-4 font-bold">Type of journalist or media actor:</h4>
          <NestedTags
            topSlug="type-of-journalist-or-media-actor"
            tags={post.type_of_journalist_or_media_actor}
          />
        </>
      )}
      {post.gender?.length && (
        <>
          <h4 class="mt-4 font-bold">Gender</h4>
          <NestedTags topSlug="gender" tags={post.gender} />
        </>
      )}
      {post.employment_status?.length && (
        <>
          <h4 class="mt-4 font-bold">Employment status</h4>
          <NestedTags
            topSlug="employment-status"
            tags={post.employment_status}
          />
        </>
      )}
    </div>
  );
});

export const SubjectsView = observer(function SubjectsView({
  post,
}: {
  post: Alert;
}) {
  const journalists = post.subjects?.filter((s) => s.kind == "journalist");
  const others = post.subjects?.filter((s) => s.kind == "other");
  return (
    <div>
      {post.subjects?.length > 0 ? (
        <>
          <h4 class="mt-4 font-bold">Who was attacked</h4>
          {journalists.length > 0 && (
            <div>
              <div class="font-semibold">
                <Tag tag={journalists[0].who} />
              </div>
              <div class="ml-8 card-text grid grid-cols-[min-content_minmax(min-content,_1fr)_minmax(min-content,_1fr)_minmax(min-content,_1fr)] gap-4">
                <div class="mt-4 text-xs italic">Amount</div>
                <div class="mt-4 text-xs italic">Type of Journalist</div>
                <div class="mt-4 text-xs italic">Gender</div>
                <div class="mt-4 text-xs italic">Employment Status</div>
                {journalists.map((s, index) => (
                  <div class="contents" key={index}>
                    <div>{s.amount}</div>
                    <div>{s.type}</div>
                    <div>{s.gender}</div>
                    <div>{s.employment_status}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {others.length > 0 && (
            <div class={"mt-4 font-semibold"}>
              <Tag tag="Other persons or entities related to media" />
            </div>
          )}
          {others.map((s, index) => (
            <div
              key={index}
              class="ml-8 card-text grid grid-cols-[min-content_max-content] gap-4"
            >
              <div>{s.amount}</div>
              <div>{s.who}</div>
            </div>
          ))}
          {post.subjects
            .filter((s) => s.kind == "general")
            .map((s, index) => (
              <div key={index} class="card-text">
                <Tag tag={s.who} />
              </div>
            ))}
        </>
      ) : (
        <LegacySubjectsView post={post} />
      )}
    </div>
  );
});

export const DetailsView = observer(function DetailsView({
  post,
  className,
  closeFunction,
  nextPost,
  previousPost,
  position,
  total,
  store,
}: {
  post: Alert;
  className: string;
  closeFunction: () => void;
  nextPost?: PreviewAlert;
  previousPost?: PreviewAlert;
  position?: number;
  total?: number;
  store: ExplorerStore;
}) {
  return (
    <div
      class={`absolute inset-0 z-aboveallcontent m-0 bg-black bg-opacity-25 backdrop-blur-sm ${className}`}
      onClick={(e) => e.target === e.currentTarget && closeFunction()}
    >
      <div class="absolute inset-0 z-aboveallcontent m-0 grid grid-cols-1 grid-rows-[auto_1fr_auto] bg-white bg-opacity-90 p-2 pr-4 shadow-xl backdrop-blur transition-all duration-500 ease-in-out dark:bg-black dark:bg-opacity-90 dark:text-white md:mx-auto md:my-10 md:max-w-screen-md md:rounded-modal md:pl-10 md:pr-20 md:pt-10 md:shadow-nm_up lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl">
        <div class="head mr-5 md:mr-0">
          <div class="w-full pb-8 text-center md:hidden">
            <h3>Violation of Media Freedom</h3>
          </div>
          <div
            class="absolute right-4 top-2 cursor-pointer text-5xl"
            onClick={() => closeFunction()}
          >
            {/* On big screens details page looks like a modal, we use a close icon. On mobile it's full screen, we use home icon. */}
            <span class="hidden md:inline">&times;</span>
            <HomeIcon class="h-8 w-8 md:hidden" />
          </div>
          <span class="absolute right-4 top-10 -mr-4 mt-0 w-8 sm:mr-0 md:mt-16">
            <a
              href={post.share_url}
              target="_blank"
              rel="noreferrer"
              class="mt-1 inline-block h-8 w-8 rounded p-0.5"
              title="Copy link to clipboard"
              onClick={(e) => {
                e.preventDefault();
                if (navigator.clipboard) {
                  navigator.clipboard
                    .writeText(post.share_url)
                    .then(() =>
                      toast.success("The URL was copied to the clipboard!"),
                    )
                    .catch(() =>
                      toast.error(
                        "Error copying to the clipboard. Please copy the URL from the browser location bar. Sorry for the inconvenience.",
                      ),
                    );
                  return;
                }
                copy(post.share_url);
                toast.success("The URL was copied to the clipboard!");
              }}
            >
              <LinkIcon />
            </a>
            <span>
              <SharingButtons url={post.share_url} text={post.share_text} />
            </span>
            <a
              href={`/desk/alerts/${post.id}`}
              target="_blank"
              rel="noreferrer"
              class="mt-1 inline-block h-8 w-8 rounded p-0.5 dark:bg-white dark:text-black"
              title="Edit this Alert (Login required)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </a>
          </span>
          <h2 class="flex flex-wrap">
            <span class="block flex-1 text-left text-steel-blue-600 text-base tracking-wide dark:text-steel-blue-300">
              <span class="font-semibold uppercase">{post.country}</span>
              {" | "}
              <span class="font-semibold">{post.shortDate}</span>
              {" | "}
              {post.type_of_incident_leaves.join(", ")}
            </span>
          </h2>
          <h1>
            <span class="mt-4 block text-center font-extrabold leading-8 text-gray-900 text-xl tracking-tight dark:text-gray-200 sm:text-4xl md:text-3xl">
              {post.title}
            </span>
          </h1>
        </div>
        <div class="body overflow-y-auto">
          <p class="mt-8">
            <MarkdownText text={post.content} />
          </p>
          <div class="mt-8 text-steel-blue-600 dark:text-steel-blue-300">
            <h4>
              Date of incident: <strong>{post.shortDate}</strong>
              {!post.has_date_of_incidence && (
                <small>
                  {"  "}
                  (No date was specified. This is the date of the first
                  publication here.)
                </small>
              )}
            </h4>
            {post.attacked_count_number > 0 && (
              <h4>
                Number of attacked persons or entities related to media:{" "}
                <strong>{post.attacked_count_number}</strong>
              </h4>
            )}
          </div>
          <div class="mt-8">
            {post.type_of_incident?.length > 0 && (
              <>
                <h4 class="mt-8 font-bold">Type of incident</h4>
                <NestedTags
                  topSlug="type-of-incident"
                  tags={post.type_of_incident}
                />
              </>
            )}
            {post.source_of_incident?.length > 0 && (
              <>
                <h4 class="mt-4 font-bold">Source of incident</h4>
                <NestedTags
                  topSlug="source-of-incident"
                  tags={post.source_of_incident}
                />
              </>
            )}
            {post.context_of_incident?.length > 0 && (
              <>
                <h4 class="mt-4 font-bold">Context of incident</h4>
                <NestedTags
                  topSlug="context-of-incident"
                  tags={post.context_of_incident}
                />
              </>
            )}
            <SubjectsView post={post} />
            {post.specific_topic?.length && (
              <>
                <h4 class="mt-4 font-bold">Specific topic</h4>
                <NestedTags
                  topSlug="specific-topic"
                  tags={post.specific_topic}
                />
              </>
            )}
            {post.project?.length && (
              <>
                <h4 class="mt-4 font-bold">Projects</h4>
                <NestedTags topSlug="project" tags={post.project} />
              </>
            )}
          </div>
          {!!post.videoId && (
            <p>
              <h3 class="mb-4 mt-8 font-bold text-lg">Video</h3>
              {[post.videoId].map((url) => (
                <a href={url} key={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              ))}
            </p>
          )}
          <div class="mt-8">
            <h3 class="mt-8 font-bold text-lg">Location</h3>
            <p class="mb-4 ml-2">{uniq(post.regionNames).join(" → ")}</p>
            <MiniMap
              loc={post.loc}
              tooltipSuffix={uniq(post.regionNames).join(" → ")}
            />
          </div>
          <h3 class="mt-8 font-bold text-lg">Verification sources</h3>
          {post.news_source_links?.length > 0 && (
            <>
              <h4 class="mt-4 font-bold">News sources</h4>
              <MarkdownText
                text={post.news_source_links}
                extraClasses="break-words"
              />
            </>
          )}
          {post.internet_source_links?.length > 0 && (
            <>
              <h4 class="mt-4 font-bold">Internet sources</h4>
              <MarkdownText
                text={post.internet_source_links}
                extraClasses="break-words"
              />
            </>
          )}
          {(post.verification_individual_count || 0) > 0 && (
            <h4 class="mt-4 font-bold">
              Number of individual contacts:{" "}
              <strong>{post.verification_individual_count}</strong>
            </h4>
          )}
          <ExternalResourceLinks post={post} />
        </div>
        <div class="relative bottom-0 grid select-none grid-cols-3 gap-2 px-4 pt-4">
          {!previousPost ? (
            <div />
          ) : (
            <div
              class="relative bottom-0 cursor-pointer truncate rounded px-1 text-center text-xl hover:bg-slate-100 dark:hover:bg-slate-800"
              onClick={() => store.setPost(previousPost.id)}
            >
              Previous
              <br />
              <MiniPreview post={previousPost} />
            </div>
          )}
          <div
            class="relative bottom-0 cursor-pointer truncate rounded px-1 text-center text-xl hover:bg-slate-100 dark:hover:bg-slate-800"
            onClick={() => closeFunction()}
          >
            Close
            <br />
            {position !== undefined &&
              position !== -1 &&
              total &&
              total > 0 && (
                <span class={"text-xs"}>
                  {position + 1} of {total} alerts
                </span>
              )}
          </div>
          {!nextPost ? (
            <div />
          ) : (
            <div
              class="relative bottom-0 cursor-pointer truncate rounded px-1 text-center text-xl hover:bg-slate-100 dark:hover:bg-slate-800"
              onClick={() => store.setPost(nextPost.id)}
            >
              Next
              <br />
              <MiniPreview post={nextPost} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
