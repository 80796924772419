import * as React from "react";
import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";

export const RegionPreview = observer(function RegionPreview({
  store,
}: {
  store: ExplorerStore;
}) {
  const region = store.hoveredRegion;
  if (!region) return null;
  return (
    <div
      class={
        "pointer-events-auto relative bottom-6 mr-8 block h-9 overflow-y-auto rounded bg-white bg-opacity-75 p-2 shadow-xl backdrop-blur-sm transition-all duration-500 ease-in-out text-sm dark:bg-black dark:bg-opacity-75 dark:text-white"
      }
    >
      {region.name}
      {region.local_name && region.local_name != region.name && (
        <> ({region.local_name})</>
      )}
    </div>
  );
});
