import { MeiliSearch, MultiSearchQuery } from "meilisearch";
import { makeAutoObservable, observable, reaction, runInAction } from "mobx";
import { errorMessageWithReload } from "explorer/errors";
import { DateRangeFilter } from "state/date-range-filter";

type EuMemberShip = -1 | -2 | -3 | -4 | -5;

type IndexGeoloc = { lat: number; lng: number };
type RegionIds =
  | [EuMemberShip, number, number, number]
  | [EuMemberShip, number, number]
  | [EuMemberShip, number]
  | [EuMemberShip];

// type IdLocRegion = {
//   id: Number;
//   _geo: IndexGeoloc;
//   region_ids: RegionIds;
// };
type PostsIndex = {
  id: number;
  _geo: IndexGeoloc;
  region_ids: RegionIds;
};

export type FilterItemOptions = {
  value: string;
  label: string;
  count: number;
  checked: boolean;
  children?: FilterItemOptions[];
};

export class MeiliStore {
  client = new MeiliSearch({
    host: new URL(window.initData.meiliUrl, document.location.href).toString(),
    apiKey: window.initData.meiliKey,
  });

  indexName = window.initData.meiliIndex ?? "alerts";
  index = this.client.index<PostsIndex>(this.indexName);

  listOptions = [
    {
      label: "Auto (default: all, zoomed: only alerts on map",
      value: undefined,
    },
    { label: "All", value: "all" },
    { label: "Only alerts on map", value: "onlymap" },
  ];
  sortOptions = [
    {
      label: "Auto (Date of Incident, when searching Best Match)",
      value: undefined,
    },
    { label: "Date of Incident (desc)", value: "timestamp:desc" },
    { label: "Date of Incident (asc)", value: "timestamp:asc" },
    // { label: "Creation Date (desc)", value: "id:desc" },
    // { label: "Creation Date (asc)", value: "id:asc" },
    { label: "Publication Date (desc)", value: "published_at:desc" },
    { label: "Publication Date (asc)", value: "published_at:asc" },
    { label: "Distance from map center", value: "fromCenter:asc" },
    { label: "Best Match (search terms)", value: "" },
  ];

  facetDistribution: Record<string, Record<string, number>> = {}; // = new Map<string, any>();
  facetDistributionExt = new Map<string, any>(); // = new Map<string, any>();

  // initially I had this as undefined, but after webpacker 6 upgrade, it wouldn't be be in the mobx observed map any more
  lastResponse: any;
  lastPreviewsResponse: any = {};
  lastPreviewsResponseLoadMoreHits: any = [];
  filters: Map<string, string[]> = new Map();
  filterOperatorAnd = new Set<string>();
  setFilterOperatorAnd(dim: string, value: boolean) {
    if (value) {
      this.filterOperatorAnd.add(dim);
    } else {
      this.filterOperatorAnd.delete(dim);
    }
  }
  setAllFilterOperatorAnd(dims: string[]) {
    this.filterOperatorAnd.clear();
    dims.forEach((d) => this.filterOperatorAnd.add(d));
  }
  idLocRegionResponse: any = {};

  currentQuery = "";
  initialized = false;
  showPreviewList = false;

  currentSortOption = this.sortOptions[0];
  currentListOption = this.listOptions[0];

  dateOfIncidentFilter = new DateRangeFilter("iyyyymmdd");
  dateOfPublicationFilter = new DateRangeFilter("published_at_iyyyymmdd");

  /* Generates the Meilisearch filter string for all filters excluding the provided one */
  createFiltersExcludingDimension(excludedDim: string): string[] {
    return [
      ...Array.from(this.filters)
        .filter(([idim]) => idim != excludedDim)
        .map(([name, values]) => {
          if (name.startsWith("exclude_")) {
            return values
              .map((v) => `"${name.substring("exclude_".length)}" != "${v}"`)
              .join(" AND ");
          }
          return values
            .map((v) => `"${name}" = "${v}"`)
            .join(this.filterOperatorAnd.has(name) ? " AND " : " OR ");
        }),
      this.dateOfIncidentFilter.filter,
      this.dateOfPublicationFilter.filter,
    ].filter((e) => e && e.length > 0);
  }

  get filterForSearch() {
    return this.createFiltersExcludingDimension(""); // we don't want to exclude any
  }

  get facetDistributionWithDisjunctivelyCountedFields() {
    return {
      ...this.facetDistribution,
      ...Object.fromEntries(this.facetDistributionExt),
    };
  }

  facetDistributionWithDisjunctivelyCountedFieldsEntries(
    field: string,
  ): [string, number][] {
    return Object.entries(
      this.facetDistributionWithDisjunctivelyCountedFields[field] ?? {},
    );
  }

  optionsForFilterChildren(
    children: InitTag[] | undefined,
    facets: [string, number][],
    field: string,
    parent: InitTag | undefined = undefined,
  ): FilterItemOptions[] {
    if (!children || !children.length) {
      return [];
    }
    return children
      .map((cat) => {
        return {
          value: cat.label,
          label: parent ? cat.label.replace(`${parent.label} `, "") : cat.label,
          count: facets.find(([value]) => value === cat.label)?.[1] ?? 0,
          checked: this.hasFilter(field, cat.label),
          children: this.optionsForFilterChildren(
            cat.children,
            facets,
            field,
            cat,
          ),
        };
      })
      .filter((cat) => cat.count > 0);
  }
  optionsForFilter(field: string, slug: string) {
    const facets =
      this.facetDistributionWithDisjunctivelyCountedFieldsEntries(field);
    return this.optionsForFilterChildren(
      window.initData.tags.find((t) => t.slug == slug)!.children,
      facets,
      field,
    );
  }

  get filterForDisjunctiveFacetCounts() {
    return new Map(
      this.filteredDimensions.map((dim) => [
        dim,
        this.createFiltersExcludingDimension(dim),
      ]),
    );
  }

  geoFilterBounds?: [number, number, number, number]; // North, West, South, East
  mapCenter: [number, number] = [0, 0]; // Lat, Lng
  zoom = 0;
  setGeoFilterBounds(north: number, west: number, south: number, east: number) {
    this.geoFilterBounds = [north, west, south, east];
  }
  setZoom(zoom: number) {
    this.zoom = zoom;
  }
  setMapCenter(lat: number, lng: number) {
    this.mapCenter = [lat, lng];
  }
  get isPreviewListGeofiltered() {
    if (this.currentListOption.value == "all") return false;
    if (typeof this.currentListOption.value == "undefined" && this.zoom < 6)
      return false;
    return true;
  }
  get geofilterForPreviewList() {
    if (!this.isPreviewListGeofiltered) return [];
    return this.geofilter;
  }
  get geofilter() {
    if (!this.geoFilterBounds) return [];
    return [
      `_geo_lat <= ${this.geoFilterBounds[0]}`,
      `_geo_lat >= ${this.geoFilterBounds[2]}`,
      `_geo_lng >= ${this.geoFilterBounds[1]}`,
      `_geo_lng <= ${this.geoFilterBounds[3]}`,
    ];
  }

  constructor() {
    makeAutoObservable(this, {
      lastResponse: observable.ref,
      lastPreviewsResponse: observable.ref,
      idLocRegionResponse: observable.ref,
      facetDistribution: observable.ref,
      facetDistributionExt: observable.shallow,
      requestProcessedPreviewList: false,
      requestIndexPreviewList: false,
      requestProcessedUpdateFacetCounts: false,
    });

    reaction(
      () =>
        this.initialized && [
          this.currentQuery,
          this.filterForSearch,
          this.filterOperatorAnd,
        ],
      (reasons) => {
        if (reasons) {
          this.updateFacetCounts();
        }
      },
      { fireImmediately: true },
    );

    reaction(
      () =>
        this.initialized &&
        this.showPreviewList && [
          this.nextRequestIndexForUpdateFacetCounts,
          this.currentSortOption,
          this.currentListOption,
          this.isPreviewListGeofiltered && [
            this.geoFilterBounds,
            this.mapCenter,
            this.zoom,
          ],
        ],
      (reasons) => {
        if (reasons) {
          this.updatePreviewList();
        }
      },
      { fireImmediately: true },
    );
  }

  requestIndexPreviewList = new Map<number, number>();
  requestProcessedPreviewList = new Map<number, number>();

  private previewListQuery(offset = 0) {
    return {
      indexUid: this.indexName,
      q: this.currentQuery,
      sort: this.sortOptionForSearch,
      limit: 100,
      offset,
      attributesToHighlight: [
        "title",
        "date",
        "region_names",
        "attacked_count",
        "type_of_incident_leaves",
        "content",
      ],
      attributesToRetrieve: ["id", "_geo"],
      attributesToCrop: ["content"],
      cropLength: 40,
      filter: this.filterForSearch.concat(this.geofilterForPreviewList),
    };
  }

  get previewListQuery0() {
    return this.previewListQuery(0);
  }

  async updatePreviewList(offset = 0) {
    if (offset > 0 && this.lastPreviewsResponse.estimatedTotalHits <= offset) {
      return;
    }
    const requestIndex = this.requestIndexPreviewList.get(offset) ?? 0;
    this.requestIndexPreviewList.set(offset, requestIndex + 1);
    this.client
      .multiSearch({
        queries: [this.previewListQuery(offset)],
      })
      .then((r) => {
        if (
          !r ||
          !r.results ||
          (this.requestProcessedPreviewList.get(offset) ?? 0) > requestIndex
        )
          return;
        this.requestProcessedPreviewList.set(offset, requestIndex);
        const res = r.results[0];
        runInAction(() => {
          if (offset === 0) {
            this.lastPreviewsResponse = res;
            this.lastPreviewsResponseLoadMoreHits = [];
          } else {
            this.lastPreviewsResponseLoadMoreHits = [
              ...this.lastPreviewsResponseLoadMoreHits,
              res,
            ];
          }
        });
      });
  }

  loadById(id: number) {
    return this.index.getDocument(id);
  }

  setCurrentQuery(q: string) {
    this.currentQuery = q;
  }

  clearFilters() {
    this.filters.clear();
    this.dateOfIncidentFilter.clear();
    this.dateOfPublicationFilter.clear();
  }

  clearFilterDimension(name: string) {
    this.filters.delete(name);
  }

  addFilters(name: string, values: string[]) {
    values.forEach((value) => this.addFilter(name, value));
  }

  addFilter(name: string, value: string) {
    if (this.hasFilter(name, value)) {
      return;
    }
    if (this.filters.has(name)) {
      this.filters.get(name)!.push(value);
    } else {
      this.filters.set(name, [value]);
    }
  }
  removeFilters(name: string, values: string[]) {
    values.forEach((value) => this.removeFilter(name, value));
  }
  removeFilter(name: string, value: string) {
    if (this.hasFilter(name, value)) {
      const values = this.filters.get(name)!;
      values.splice(values.indexOf(value), 1);
      if (values.length === 0) this.filters.delete(name);
    }
  }

  hasFilter(name: string, value: string) {
    if (!this.filters.has(name)) return false;
    const values = this.filters.get(name)!;
    return values.includes(value);
  }

  togglefilter(name: string, value: string) {
    if (this.hasFilter(name, value)) {
      this.removeFilter(name, value);
    } else {
      this.addFilter(name, value);
      const name_without_exclude = name.substring("exclude_".length);
      if (
        name.startsWith("exclude_") &&
        this.hasFilter(name_without_exclude, value)
      ) {
        this.removeFilter(name_without_exclude, value);
      }
    }
  }

  setCurrentListOption(listOption?: string | null) {
    this.currentListOption =
      this.listOptions.find((o) => o.value === listOption) ||
      this.listOptions[0];
  }
  setCurrentSort(sort?: string | null) {
    this.currentSortOption =
      this.sortOptions.find((o) => o.value === sort) || this.sortOptions[0];
  }

  get filteredDimensions() {
    return Array.from(this.filters.keys());
  }

  get sortOptionForSearch() {
    const rule =
      this.currentSortOption?.value ||
      (this.currentQuery.length > 0 ? undefined : "timestamp:desc");
    if (rule == "fromCenter:asc") {
      //const centerString = this.geoFilterBounds
      return [`_geoPoint(${this.mapCenter.join(",")}):asc`];
    }
    if (rule) return [rule];
    return undefined;
  }

  nextRequestIndexForUpdateFacetCounts = 0;
  requestProcessedUpdateFacetCounts = 0;

  updateFacetCounts() {
    const baseQuery = {
      indexUid: this.indexName,
      q: this.currentQuery,
      limit: 100000, // setting a too low limit will cause the facet counts to be wrong AND markers missing on the map
    };
    const queries: MultiSearchQuery[] = [];

    const useRegionsQuery = !Object.keys(this.idLocRegionResponse).length;

    if (useRegionsQuery) {
      queries.push({
        ...baseQuery,
        q: null,
        attributesToRetrieve: ["id", "_geo", "region_ids"],
        filter: "region_ids != -1",
      });
    }

    const facetQuery = {
      ...baseQuery,
      sort: this.sortOptionForSearch,
      facets: ["*"],
      filter: this.filterForSearch,
      attributesToRetrieve: ["id"], // this is needed to filter down the markers, so we can't just remove it
    };
    queries.push(facetQuery);
    const filterForDisjunctiveFacetCountsQueries = this.filteredDimensions
      .filter((dim) => !this.filterOperatorAnd.has(dim))
      .filter((dim) => !dim.startsWith("exclude_"))
      .map((dim) => ({
        ...baseQuery,
        facets: [dim],
        filter: this.filterForDisjunctiveFacetCounts.get(dim),
        attributesToRetrieve: [],
        limit: 0,
      }));
    queries.push(...filterForDisjunctiveFacetCountsQueries);
    const requestIndex = this.nextRequestIndexForUpdateFacetCounts++;
    this.client
      .multiSearch({
        queries,
      })
      .then((res) => {
        if (this.requestProcessedUpdateFacetCounts > requestIndex) {
          return;
        }
        this.requestProcessedUpdateFacetCounts = requestIndex;
        runInAction(() => {
          if (useRegionsQuery) {
            // For now, the only thing here is updateAllPostRegions
            this.idLocRegionResponse = res["results"][0];
          }
          const responseResults = res["results"].slice(useRegionsQuery ? 1 : 0);
          this.lastResponse = responseResults[0];

          this.facetDistribution =
            (this.lastResponse?.facetDistribution as any) ?? {};

          const results = responseResults.slice(1);
          this.facetDistributionExt.clear();
          results.forEach((res) => {
            if (!res.facetDistribution) return;
            const dim = Object.keys(res.facetDistribution)[0];
            this.facetDistributionExt.set(dim, res.facetDistribution[dim]);
          });
        });
      })
      .catch((e) => {
        if (e.code == "index_not_found") {
          errorMessageWithReload(
            "Alert Explorer is currently unable to load alerts.",
            `Meili: index ${this.indexName} not found. Try reindexing.`,
            e,
          );
        } else if (e.code == "invalid_api_key") {
          errorMessageWithReload(
            "Alert Explorer has been updated. Reloading the page to get latest version.",
            "Meili: invalid api key.",
            e,
            100,
            2000,
          );
        } else if (e.code == "invalid_filter") {
          errorMessageWithReload(
            "Alert Explorer is currently unable to load alerts.",
            "Meili: invalid filter. Probably reindexing is in progress and settings not yet applied.",
            e,
          );
        } else {
          errorMessageWithReload(
            "Alert Explorer is currently unable to load alerts.",
            "Unknown error",
            e,
          );
        }
      });
  }
}
