import { ExplorerStore } from "explorer/store";
import { toast } from "react-hot-toast";
import { CSSProperties } from "react";
import { LinkDuo, QuestionDuo } from "components/svgs";
import {
  HelpButton,
  ToggleChartsButton,
  ToggleListButton,
} from "components/menu-buttons";
import { StandAloneLink } from "components/standalone-header";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let helpRunning = false;

async function sleepWithControl(ms: number) {
  await sleep(ms);
  if (!helpRunning) {
    toast.dismiss();
    toast(
      <div>
        Help stopped. Press{" "}
        <HelpButton
          classNames="h-9 w-9 button"
          label={false}
          onClick={() => void 0}
        />{" "}
        to start again
      </div>,
      { icon: "🛑" },
    );
    throw new Error("help was stopped");
  }
}

const helpToastStyles: CSSProperties = {
  borderRadius: "0.5rem",
  backgroundColor: "#FFD200",
};
export function showHelp(store: ExplorerStore) {
  console.log("showHelp");
  if (helpRunning) {
    helpRunning = false;
    return;
  }
  helpRunning = true;
  if (store.window.isMd) {
    showDesktopHelp(store);
  } else {
    showMobileHelp(store);
  }
}

export async function showDesktopHelp(store: ExplorerStore) {
  try {
    toast("Welcome to the Mapping Media Freedom alert explorer.", {
      duration: Infinity,
      icon: "👋",
      style: helpToastStyles,
    });
    await sleepWithControl(1500);
    toast("Here, you can browse, search and filter alerts.", {
      duration: Infinity,
      icon: "🔎",
      style: helpToastStyles,
    });
    await sleepWithControl(1500);
    toast("We'll now give you a short overview tour.", {
      duration: Infinity,
      icon: "📖",
      style: helpToastStyles,
    });
    await sleepWithControl(3000);
    toast.dismiss();
    toast(
      <div>
        <p>This is the help button where you started this guide.</p>
      </div>,
      {
        duration: Infinity,
        icon: <QuestionDuo className="button h-9" />,
        position: "top-left",
        style: helpToastStyles,
      },
    );
    toast(
      <div>
        <p>
          You can just click here again to <b>stop</b> and click again to{" "}
          <b>start over</b>.
        </p>
      </div>,
      {
        duration: Infinity,
        icon: "🔁",
        position: "top-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();
    toast(
      <div>
        <p>Search and filtering is available in the top center.</p>
      </div>,
      {
        duration: Infinity,
        icon: "🔎",
        position: "top-center",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(3000);
    toast.dismiss();

    toast(
      <div>
        <p>List of alerts and charts are on the right.</p>
      </div>,
      {
        duration: Infinity,
        icon: "📊",
        position: "top-right",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(1000);

    toast(
      <div>
        <p>
          The <b>alert list</b> is shown by default and can be toggled off.
        </p>
      </div>,
      {
        duration: Infinity,
        icon: (
          <ToggleListButton
            classNames="h-8 w-9 button"
            label={false}
            onClick={() => void 0}
          />
        ),
        position: "top-right",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(1000);
    toast(
      <div>
        <p>
          <b>Charts</b> can be toggled on using the button on the top left.
        </p>
      </div>,
      {
        duration: Infinity,
        icon: (
          <ToggleChartsButton
            classNames="h-8 w-9 button"
            label={false}
            onClick={() => void 0}
          />
        ),
        position: "top-right",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();

    toast(
      <div>
        You can toggle <b>alert list</b> on and off here.
      </div>,
      {
        duration: Infinity,
        icon: (
          <ToggleListButton
            classNames="h-8 w-9 button"
            label={false}
            onClick={() => void 0}
          />
        ),
        position: "top-left",
        style: helpToastStyles,
      },
    );
    toast(
      <div>
        You can toggle <b>charts</b> on and off here.
      </div>,
      {
        duration: Infinity,
        icon: (
          <ToggleChartsButton
            classNames="h-8 w-9 button"
            label={false}
            onClick={() => void 0}
          />
        ),
        position: "top-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();

    if (!store.window.isStandalone) {
      toast(
        <div>
          You currently see the <b>embedded</b> Alert Explorer.
        </div>,
        {
          duration: Infinity,
          position: "top-left",
          style: helpToastStyles,
        },
      );
      await sleepWithControl(300);
      toast(
        <div>
          You can switch to <b>standalone</b> mode to see the map full screen.
        </div>,
        {
          duration: Infinity,
          icon: <StandAloneLink classNames="h-8 w-9 button" store={store} />,
          position: "top-left",
          style: helpToastStyles,
        },
      );
      await sleepWithControl(5000);
      toast.dismiss();
    }

    toast(
      <div>
        The URL to the current page here can be copied to the clipboard here,
        e.g. for an e-mail, a document or an article, etc.
      </div>,
      {
        duration: 5000,
        icon: <LinkDuo className="button h-9" />,
        position: "top-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();

    toast(
      <div>
        <p>You can control map layers and zoom on the bottom left.</p>
      </div>,
      {
        duration: 3000,
        icon: "🌍",
        position: "bottom-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(3000);
    toast.dismiss();

    toast(
      <div>
        <p class="text-2xl">That's it!</p>
      </div>,
      {
        duration: Infinity,
        icon: "🎉",
        position: "top-center",
        style: helpToastStyles,
      },
    );
    toast(
      <div>
        <p>Please contact us if you need more help!</p>
      </div>,
      {
        duration: Infinity,
        icon: "📧",
        position: "top-center",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();
  } catch (e) {
    if (e.message == "help was stopped") {
      return;
    }
    throw e;
  }
}
export async function showMobileHelp(store: ExplorerStore) {
  try {
    toast("Welcome to the Mapping Media Freedom alert explorer.", {
      duration: Infinity,
      icon: "👋",
      style: helpToastStyles,
    });
    await sleepWithControl(1500);
    toast("Here, you can browse, search and filter alerts.", {
      duration: Infinity,
      icon: "🔎",
      style: helpToastStyles,
    });
    await sleepWithControl(1500);
    toast("We'll now give you a short overview tour.", {
      duration: Infinity,
      icon: "📖",
      style: helpToastStyles,
    });
    await sleepWithControl(3000);
    toast.dismiss();

    toast(
      <div>
        <p>This is the help button where you started this guide.</p>
      </div>,
      {
        duration: Infinity,
        icon: <QuestionDuo className="button h-9" />,
        position: "top-left",
        style: helpToastStyles,
      },
    );
    toast(
      <div>
        <p>
          You can just click here again to <b>stop</b> and click again to{" "}
          <b>start over</b>.
        </p>
      </div>,
      {
        duration: Infinity,
        icon: "🔁",
        position: "top-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();

    toast(
      <div>
        <p>Search and filtering is available in the top center.</p>
      </div>,
      {
        duration: Infinity,
        icon: "🔎",
        position: "top-center",
        style: helpToastStyles,
      },
    );

    toast(
      <div>
        <p>Map, filters, alerts list and charts are below.</p>
      </div>,
      {
        duration: Infinity,
        icon: "👇",
        position: "top-center",
        style: helpToastStyles,
      },
    );
    toast(
      <div>
        <p>
          Choose <b>Map</b>, <b>Filters</b>, <b>List</b> or <b>Charts</b> here
          in the bottom navigation.
        </p>
      </div>,
      {
        duration: Infinity,
        icon: "💡",
        position: "bottom-center",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(7000);
    toast.dismiss();

    toast(
      <div>
        <p>
          You can control <b>map layers</b> and zoom on the bottom left.
        </p>
      </div>,
      {
        duration: Infinity,
        icon: "🌍",
        position: "bottom-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(4000);
    toast.dismiss();

    if (!store.window.isStandalone) {
      toast(
        <div>
          You currently see the <b>embedded</b> Alert Explorer.
        </div>,
        {
          duration: Infinity,
          position: "top-left",
          style: helpToastStyles,
        },
      );
      await sleepWithControl(300);
      toast(
        <div>
          You can switch to <b>standalone</b> mode to see the map full screen.
        </div>,
        {
          duration: Infinity,
          icon: <StandAloneLink classNames="h-8 w-9 button" store={store} />,
          position: "top-left",
          style: helpToastStyles,
        },
      );
      await sleepWithControl(5000);
      toast.dismiss();
    }

    toast(
      <div>
        The URL to the current page here can be copied to the clipboard here,
        e.g. for an e-mail, a document or an article, etc.
      </div>,
      {
        duration: 5000,
        icon: <LinkDuo className="button h-9" />,
        position: "top-left",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();

    toast(
      <div>
        <p class="text-2xl">That's it!</p>
      </div>,
      {
        duration: Infinity,
        icon: "🎉",
        position: "top-center",
        style: helpToastStyles,
      },
    );
    toast(
      <div>
        <p>Please contact us if you need more help!</p>
      </div>,
      {
        duration: Infinity,
        icon: "📧",
        position: "top-center",
        style: helpToastStyles,
      },
    );
    await sleepWithControl(5000);
    toast.dismiss();
  } catch (e) {
    if (e.message == "help was stopped") {
      return;
    }
    throw e;
  }
}
