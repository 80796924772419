import React from "react";

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

export function MapControl({
  children,
  position,
  classNames = "",
  classNamesInner = "",
}: React.PropsWithChildren<{
  position: keyof typeof POSITION_CLASSES;
  classNames?: string;
  classNamesInner?: string;
}>) {
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <div className={`${positionClass} ${classNames}`}>
      <div className={`leaflet-control leaflet-bar ${classNamesInner}`}>
        {children}
      </div>
    </div>
  );
}
