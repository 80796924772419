import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";
import { Disclosure } from "@headlessui/react";
import {
  FilterIcon,
  MinusSmIcon,
  PlusSmIcon,
  XIcon,
} from "@heroicons/react/outline";
import { runInAction } from "mobx";
import { SearchIcon } from "@heroicons/react/solid";
import { ResultSummary } from "components/result-summary";
import { FilterItemOptions } from "state/explorer-search";
import {
  ActiveDateRangeFilter,
  FilterDateRange,
} from "components/filter-date-range";
import {
  SelectedDimensionHeader,
  SelectedTag,
} from "components/filter-selected";

const PanelSlideButton = ({
  onClick,
  direction,
}: {
  onClick: any;
  direction: string;
}) => (
  <button
    aria-label={
      direction == "down" ? "Expand filter panel" : "Collapse filter panel"
    }
    class={`h-6 w-full text-center dark:text-white ${
      direction == "down"
        ? "translate-y-2 hover:translate-y-2.5"
        : "translate-y-0 rotate-180 hover:translate-y-[-0.125rem]"
    }`}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-18 mx-auto h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      preserveAspectRatio="xMinYMin slice"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </button>
);

const ActiveFiltersOverview = observer(function ActiveFiltersOverview({
  store,
  extraClasses = "",
}: {
  store: ExplorerStore;
  extraClasses?: string;
}) {
  if (!store.filtersActive) return null;
  if (!store.meili.lastResponse) return null;

  return (
    <div class={extraClasses}>
      <ActiveDateRangeFilter
        label="Date of Incident"
        filter={store.meili.dateOfIncidentFilter}
      />
      <ActiveDateRangeFilter
        label="Date of Publication"
        filter={store.meili.dateOfPublicationFilter}
      />
      {!store.filterPanelOpen && store.meili.filteredDimensions.length > 2 ? (
        <p class="mt-4 cursor-pointer" onClick={() => store.openFilterPanel()}>
          Many filters applied. Click here see all.
        </p>
      ) : (
        store.meili.filteredDimensions.map((dimension) => {
          const label = store.dimensionLabel(dimension);
          return (
            <p key={dimension} class="mt-1">
              {!store.filterPanelOpen &&
              store.meili.filters.get(dimension)!.length > 3 ? (
                <>
                  <SelectedDimensionHeader
                    onClick={() => store.meili.clearFilterDimension(dimension)}
                    title={`Clear filter for ${label}`}
                  >
                    {label}
                  </SelectedDimensionHeader>{" "}
                  <SelectedTag
                    key="many"
                    onClick={() => store.meili.clearFilterDimension(dimension)}
                  >
                    {store.meili.filters.get(dimension)!.length} selected
                  </SelectedTag>{" "}
                  <span
                    class="cursor-pointer"
                    onClick={() => store.openFilterPanel()}
                  >
                    Show all
                  </span>
                </>
              ) : (
                <>
                  <SelectedDimensionHeader
                    onClick={() => store.meili.clearFilterDimension(dimension)}
                    title={`Clear filter for ${label}`}
                  >
                    {label}
                  </SelectedDimensionHeader>{" "}
                  {store.meili.filters.get(dimension)!.map((value) => (
                    <SelectedTag
                      onClick={() => store.meili.removeFilter(dimension, value)}
                      key={value}
                      extraClassName={
                        store.meili
                          .facetDistributionWithDisjunctivelyCountedFields[
                          dimension
                        ]?.[value] || dimension.startsWith("exclude_")
                          ? "text-curious-blue-700"
                          : "text-punch-400"
                      }
                      removeLabelExt={` ${value} `}
                    >
                      <span>
                        {value}{" "}
                        {!dimension.startsWith("exclude_") &&
                          dimension !== "id" && (
                            <small>
                              {" "}
                              {store.meili
                                .facetDistributionWithDisjunctivelyCountedFields[
                                dimension
                              ]?.[value] ?? 0}
                            </small>
                          )}
                      </span>
                    </SelectedTag>
                  ))}
                </>
              )}
            </p>
          );
        })
      )}
    </div>
  );
});

const QuickFilterFromQuery = observer(function QuickFilterFromQuery({
  store,
}: {
  store: ExplorerStore;
}) {
  if (!store.meili.currentQuery || store.meili.currentQuery.length < 3)
    return null;

  const candidates = store.meili.currentQuery.split(" ");
  const lastCandidate = candidates[candidates.length - 1].toLowerCase();
  if (lastCandidate.length < 3) return null;

  const suggestions: { dim: string; value: string; count: number }[] = [];
  Object.entries(
    store.meili.facetDistributionWithDisjunctivelyCountedFields,
  ).forEach(([dim, pairs]) => {
    if (!pairs) return;
    if (dim == store.dimensionLabel(dim)) return;
    Object.entries(pairs).forEach(([value, count]) => {
      if (
        !store.meili.filters.get(dim) ||
        !store.meili.filters.get(dim)!.includes(value)
      ) {
        if (value.toLowerCase().includes(lastCandidate)) {
          suggestions.push({ dim, value, count });
        }
      }
    });
  });
  if (suggestions.length == 0) return null;
  return (
    <div class="text-sm">
      <p>Suggestions for adding filters:</p>
      <ul>
        {suggestions.slice(0, 8).map((s) => (
          <li
            key={s.dim + s.value}
            class="group mx-1 mt-2 inline-flex cursor-pointer items-center rounded-full bg-curious-blue-100 py-0.5 pl-1 pr-2.5 font-medium text-curious-blue-700 text-sm"
            onClick={() => store.meili.addFilter(s.dim, s.value)}
          >
            <button
              type="button"
              className="mr-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-curious-blue-400 focus:bg-curious-blue-500 focus:text-white focus:outline-none group-hover:bg-curious-blue-300 group-hover:text-curious-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
            {store.dimensionLabel(s.dim)}: {s.value}{" "}
            <small class={"ml-1"}>{s.count}</small>
          </li>
        ))}
      </ul>
      {store.filtersActive && <p class="-mb-4 mt-4">Active filters:</p>}
    </div>
  );
});

const ResultAndQuickFilterPanel = observer(function ResultAndQuickFilterPanel({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class="pointer-events-auto relative mt-2 block max-h-[calc(100%-theme(spacing.14))] min-h-[theme(spacing.9)] w-full overflow-y-auto rounded-lg bg-white bg-opacity-75 p-2 shadow-xl backdrop-blur-sm transition-all duration-500 ease-in-out dark:bg-black dark:bg-opacity-75 dark:text-white md:max-w-md">
      <QuickFilterFromQuery store={store} />
      <ActiveFiltersOverview store={store} extraClasses="text-sm mt-4" />

      <PanelSlideButton
        direction={"down"}
        onClick={() => store.openFilterPanel()}
      />
    </div>
  );
});

const FilterPanel = observer(function FilterPanel({
  store,
}: {
  store: ExplorerStore;
}) {
  if (!store.filterPanelOpen) return null;

  return (
    <div class="pointer-events-auto relative mt-2 block max-h-[calc(100%-theme(spacing.14))] w-full rounded-lg bg-white bg-opacity-75 shadow-xl backdrop-blur-sm transition-all duration-500 ease-in-out hover:bg-opacity-100 hover:shadow-2xl dark:bg-black dark:bg-opacity-75 dark:text-white md:max-w-md">
      <div class="max-h-[calc(100%-theme(spacing.6))] w-full overflow-y-auto p-2">
        <ResultSummary store={store} />
        <ActiveFiltersOverview store={store} extraClasses="text-sm mt-4" />
        <FiltersList store={store} />
        {store.loggedIn ? (
          <FilterConversions store={store} />
        ) : (
          <small class="block text-right mt-4 opacity-30 hover:opacity-60">
            <a href={"/users/sign_in"}>Log in</a>
          </small>
        )}
      </div>
      <PanelSlideButton
        direction={"up"}
        onClick={() => store.closeFilterPanel()}
      />
    </div>
  );
});

const OptionLine = observer(function OptionLine_({
  option,
  section,
  store,
  parent_checked_or_indeterminate = false,
}: {
  option: FilterItemOptions;
  section: any;
  store: ExplorerStore;
  parent_checked_or_indeterminate?: boolean;
}) {
  return (
    <div className="flex items-center justify-between pl-2 group">
      <label className="text-gray-600 text-sm dark:text-gray-300 flex">
        <input
          name={`${section.id}[]`}
          defaultValue={option.value}
          type="checkbox"
          checked={option.checked}
          indeterminate={parent_checked_or_indeterminate && !option.checked}
          onChange={() => store.meili.togglefilter(section.id, option.value)}
          className="parent-selectable form-checkbox self-center h-4 w-4 rounded border-gray-300 text-curious-blue-600 focus:ring-curious-blue-500 mr-3"
        />
        <div className="inline-block">
          {option.label}{" "}
          {option.count && (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 font-medium text-gray-800 text-xs">
              {" "}
              {option.count}{" "}
            </span>
          )}
        </div>
      </label>
      <div
        onClick={() =>
          store.meili.togglefilter(`exclude_${section.id}`, option.value)
        }
        className="invisible group-hover:visible cursor-pointer rounded border border-transparent hover:border-punch-400 transition-all flex items-center"
        title={`Exclude ${option.value}`}
      >
        <XIcon class="h-4 w-4" />
      </div>
    </div>
  );
});

const OptionChildren = observer(function OptionChildren_({
  option,
  section,
  store,
  parent_checked_or_indeterminate = false,
}: {
  option: FilterItemOptions;
  section: any;
  store: ExplorerStore;
  parent_checked_or_indeterminate?: boolean;
}) {
  return (
    <>
      {option.children && option.children.length > 0 && (
        <div className="ml-4 mt-2 space-y-2">
          {option.children.map((child) => (
            <div key={child.value}>
              <OptionLine
                option={child}
                section={section}
                store={store}
                parent_checked_or_indeterminate={
                  parent_checked_or_indeterminate || option.checked
                }
              />
              <OptionChildren
                option={child}
                store={store}
                section={section}
                parent_checked_or_indeterminate={
                  parent_checked_or_indeterminate || option.checked
                }
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
});

const FilterConversions = observer(function FiltersList({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class="mt-4 text-sm">
      <h3 class="text-lg font-semibold">
        Advanced functions{" "}
        <small class="font-normal">(only visible to logged in users)</small>
      </h3>
      <a
        href="#"
        className="m-4 btn btn-sm"
        target="_blank"
        onClick={(event) => {
          const url = new URL(window.location.href);
          url.pathname = "/explorer";
          url.search = `f.id=${store.meili.lastResponse.hits.map((hit) => hit.id).join(",")}`;
          if (url.href.length > 2000) {
            alert(
              `The resulting URL is very long (${url.href.length} characters). Please consider an alternative way of sharing.`,
            );
            event.preventDefault();
          }
          event.target.setAttribute("href", url.href);
        }}
      >
        Convert current results to ID list
      </a>
      <a
        href="#"
        className="m-4 btn btn-sm"
        target="_blank"
        onClick={(event) => {
          const url = new URL(window.location.href);
          url.pathname = "/explorer";
          url.search = `f.exclude_id=${store.meili.lastResponse.hits.map((hit) => hit.id).join(",")}`;
          if (url.href.length > 2000) {
            alert(
              `The resulting URL is very long (${url.href.length} characters). Please consider an alternative way of sharing.`,
            );
            event.preventDefault();
          }
          event.target.setAttribute("href", url.href);
        }}
      >
        Convert current results to inverted ID list
      </a>
    </div>
  );
});

const FiltersList = observer(function FiltersList({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <form className="max-h-[calc(100%-theme(spacing.4)] mt-4 overflow-y-auto">
      <FilterDateRange
        label="Date of Incident"
        filter={store.meili.dateOfIncidentFilter}
        facets={
          store.meili.facetDistributionWithDisjunctivelyCountedFields.date
        }
      />
      {store.filtersForUI.map((section) => (
        <Disclosure
          as="div"
          key={section.id}
          className="border-b border-gray-200 py-6"
        >
          {({ open }) => (
            <>
              <h3 className="-my-3 flow-root">
                <Disclosure.Button className="xbg-white flex w-full items-center justify-between py-3 text-gray-400 text-sm hover:text-gray-500 dark:hover:text-gray-300">
                  <span
                    className={`font-medium ${
                      store.meili.filteredDimensions.includes(section.id)
                        ? "text-curious-blue-600"
                        : "text-gray-900 dark:text-gray-200"
                    }`}
                  >
                    {store.dimensionLabel(section.id)}
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <>
                        {section.multiValue && (
                          <div
                            class={"mr-8 inline-flex text-right text-sm"}
                            onClick={(e) => {
                              e.stopPropagation();
                              store.meili.setFilterOperatorAnd(
                                section.id,
                                !store.meili.filterOperatorAnd.has(section.id),
                              );
                            }}
                            onMouseEnter={(e) => e.stopPropagation()}
                            onMouseLeave={(e) => e.stopPropagation()}
                          >
                            <span
                              class={`${
                                store.meili.filterOperatorAnd.has(section.id)
                                  ? "text-grey-400"
                                  : "text-curious-blue-600"
                              } mr-3`}
                            >
                              Any
                            </span>
                            <input
                              type="checkbox"
                              className="toggle text-curious-blue-600 checked:border-curious-blue-600 checked:bg-curious-blue-600 focus:ring-curious-blue-500"
                              checked={store.meili.filterOperatorAnd.has(
                                section.id,
                              )}
                              onChange={(e) =>
                                store.meili.setFilterOperatorAnd(
                                  section.id,
                                  e.currentTarget.checked,
                                )
                              }
                            />
                            <span
                              class={`${
                                store.meili.filterOperatorAnd.has(section.id)
                                  ? "text-curious-blue-600"
                                  : "text-grey-400"
                              } ml-3`}
                            >
                              All
                            </span>
                          </div>
                        )}
                        <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                      </>
                    ) : (
                      <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>
              <Disclosure.Panel className="pt-6">
                <div className="space-y-4">
                  {section.options.map((option, optionIdx: number) =>
                    option.value == "separator" ? (
                      <div key={`Separator${optionIdx}`} className="ml-2">
                        {option.label}
                      </div>
                    ) : (
                      <div key={option.value}>
                        <OptionLine
                          option={option}
                          section={section}
                          store={store}
                        />
                        <OptionChildren
                          option={option}
                          store={store}
                          section={section}
                          parent_checked_or_indeterminate={option.checked}
                        />
                      </div>
                    ),
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
      {store.loggedIn && (
        <div>
          <h3 class="text-lg font-semibold mt-4">
            Advanced filters{" "}
            <small class="font-normal">(only visible to logged in users)</small>
          </h3>

          <FilterDateRange
            label="Date of Publication"
            filter={store.meili.dateOfPublicationFilter}
            facets={
              store.meili.facetDistributionWithDisjunctivelyCountedFields
                .published_at_date
            }
            extraClasses="border-t-0"
          />
        </div>
      )}
    </form>
  );
});
export const FilterBar = observer(function FilterBar({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class="z-aboveallcontent mt-2 flex max-h-[calc(100%-theme(spacing.2))] flex-1 flex-col items-center justify-start pr-2 md:pl-2">
      <FilterButtonBar store={store} />
      {store.filterPanelOpen ? (
        <FilterPanel store={store} />
      ) : (
        <ResultAndQuickFilterPanel store={store} />
      )}
    </div>
  );
});
export const FilterButtonBar = observer(function FilterButtonBar({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class="pointer-events-auto flex w-full flex-row md:max-w-md">
      <div class="w-full shadow-xl focus-within:shadow-2xl">
        <label htmlFor="search" class="sr-only">
          Search
        </label>
        <div class="relative">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            class="form-input block max-h-9 w-full rounded-md border-0 bg-white bg-opacity-75 py-2 pl-10 pr-3 placeholder-gray-500 backdrop-blur-sm transition-all duration-300 text-sm hover:bg-opacity-90 focus:border-curious-blue-500 focus:bg-opacity-90 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-4 focus:ring-curious-blue-500 dark:bg-black dark:bg-opacity-75 dark:text-white dark:placeholder-gray-300 dark:focus:text-gray-100 sm:text-sm"
            placeholder="Search and Filter"
            type="search"
            autoComplete="off"
            value={store.meili.currentQuery}
            onChange={(e) => store.meili.setCurrentQuery(e.target.value)}
            onFocus={() => store.closeFilterPanel()}
          />
        </div>
      </div>
      <div class="shadow-xl focus-within:shadow-2xl">
        <button
          aria-label="Filter"
          class={`relative ml-1 h-9 w-9 rounded  border-0 bg-opacity-75 p-2 text-gray-600 shadow backdrop-blur-sm transition-all duration-300 hover:bg-opacity-90 hover:shadow-2xl dark:bg-opacity-75 dark:text-white ${
            store.filterPanelOpen ? "ring" : ""
          } ${
            store.filtersActive
              ? "bg-curious-blue-400 dark:bg-curious-blue-600"
              : "bg-white dark:bg-black"
          }`}
          onClick={() =>
            runInAction(() => (store.filterPanelOpen = !store.filterPanelOpen))
          }
          title="Apply filters"
        >
          <FilterIcon class="h-full w-full" />
          {store.filterPanelOpen && (
            <div
              class="absolute bottom-auto left-auto right-0 top-0 -translate-y-1/2 translate-x-1/3 transform rounded-full bg-punch-300 p-0.5 dark:bg-punch-500 dark:text-white"
              onClick={() => store.closeFilterPanel()}
            >
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </div>
          )}
        </button>
      </div>
      {/*<SortButton store={store}/>*/}
    </div>
  );
});
