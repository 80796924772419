import { observer } from "mobx-react-lite";
import React, { ReactNode, useCallback } from "react";
import {
  AttributionControl,
  LayersControl,
  MapContainer,
  TileLayer,
  useMapEvents,
  ZoomControl,
} from "react-leaflet";
import * as L from "leaflet";
import { LatLngExpression } from "leaflet";
import markerIcon from "images/map-icon.svg";
import markerShadow from "images/map-icon_shadow.svg";
import { makeAutoObservable, reaction, runInAction } from "mobx";

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

export class MapStore {
  initialCenter: LatLngExpression =
    // focussing more and more east the more space we have, because atlantic is less interesting than continent
    window.innerWidth < 550
      ? [52.2, 12.2] // mobile view, little space
      : window.innerWidth < 768
        ? [52.7, 21.4] // this is still mobile view
        : window.innerWidth < 1025
          ? [52.7, 24.0] // small desktop view. List is on by default
          : window.innerWidth < 1728
            ? [52.7, 34.0] // common desktop view
            : // zooming a bit more west again because of changed initial zoom, see below
              [52.2, 28.4]; // bigger desktop, using higher zoom by default
  initialZoom = window.innerHeight >= 1050 && window.innerWidth >= 1728 ? 5 : 4;

  layersControl?: L.Control.Layers;
  layerOSMVisible = false;
  choroplethCountriesVisible = true;
  choroplethRegionsVisible = false;
  choroplethCountiesVisible = false;

  get isAnyChoroplethVisible() {
    return (
      this.choroplethCountriesVisible ||
      this.choroplethRegionsVisible ||
      this.choroplethCountiesVisible
    );
  }

  zoom = this.initialZoom;
  setZoom(level: number) {
    this.zoom = level;
  }

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.zoom >= 7,
      (zoomIsHigh) => {
        this.layerOSMVisible = zoomIsHigh;
      },
    );

    reaction(
      () => this.isAnyChoroplethVisible,
      (anyChoropleth) =>
        anyChoropleth
          ? document.body.classList.add("choropleth")
          : document.body.classList.remove("choropleth"),
      { fireImmediately: true },
    );
  }
}
const mapStore = new MapStore();

function MapEvents() {
  const map = useMapEvents({
    zoomend(_e: L.LeafletEvent) {
      mapStore.setZoom(map.getZoom());
    },
    overlayadd(e) {
      console.log("OverlayAdd", e.name);
      if (e.name == "Countries") {
        runInAction(() => (mapStore.choroplethCountriesVisible = true));
      }
      if (e.name == "Regions") {
        runInAction(() => (mapStore.choroplethRegionsVisible = true));
      }
      if (e.name == "Counties") {
        runInAction(() => (mapStore.choroplethCountiesVisible = true));
      }
    },
    overlayremove(e) {
      console.log("OverlayRemove", e.name);
      if (e.name == "Countries") {
        runInAction(() => (mapStore.choroplethCountriesVisible = false));
      }
      if (e.name == "Regions") {
        runInAction(() => (mapStore.choroplethRegionsVisible = false));
      }
      if (e.name == "Counties") {
        runInAction(() => (mapStore.choroplethCountiesVisible = false));
      }
    },
  });
  return null;
}

export const ExplorerMap = observer(function ExplorerMap({
  children,
}: {
  children: ReactNode;
}) {
  const layersControlRef = useCallback(
    (layersControl: L.Control.Layers) =>
      (mapStore.layersControl = layersControl),
    []
  );
  return (
    <MapContainer
      id="map"
      className="mmf-fullscreen absolute inset-0 flex-1"
      center={mapStore.initialCenter}
      zoom={mapStore.initialZoom}
      maxZoom={15}
      minZoom={3}
      maxBounds={[
        [-60, 270],
        [90, -180],
      ]}
      placeholder={<div>Loading Map...</div>}
      style={{
        backgroundColor: "rgb(255,255,255,0)",
      }}
      zoomControl={false}
      attributionControl={false}
      doubleClickZoom={false}
    >
      <MapEvents />
      <AttributionControl
        position="bottomleft"
        prefix={`<a target="_blank" href="https://www.mappingmediafreedom.org/imprint/">Imprint</a>`}
      />
      <ZoomControl position="bottomleft" />
      <LayersControl position="bottomleft" ref={layersControlRef}>
        <LayersControl.Overlay
          checked={mapStore.layerOSMVisible}
          name="OpenStreetMap"
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={19}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.Overlay>
        {children}
      </LayersControl>
    </MapContainer>
  );
});
