import { ReactNode } from "react";
import { TrashIcon } from "@heroicons/react/outline";

export const SelectedDimensionHeader = ({
  onClick,
  title,
  key = "default",
  children,
}: {
  onClick: any;
  title: string;
  key?: string;
  children: ReactNode;
}) => (
  <button
    onClick={onClick}
    key={key}
    title={title}
    class={
      "group mr-0.5 mt-2 inline-flex flex-shrink-0 items-center justify-center rounded-2xl py-0.5 pl-1 pr-2 text-steel-blue-500 hover:bg-gray-200 hover:bg-opacity-50 focus:bg-curious-blue-500 focus:text-white focus:outline-none group-hover:bg-curious-blue-300 group-hover:text-curious-blue-500 dark:text-curious-blue-400 dark:hover:bg-gray-600"
    }
  >
    <TrashIcon className="invisible mr-0.5 inline-block h-full w-3 group-hover:visible" />
    {children}
  </button>
);

export const SelectedTag = ({
  onClick,
  key,
  children,
  removeLabelExt = "",
  extraClassName = "",
}: {
  onClick: any;
  key: string;
  children: ReactNode;
  removeLabelExt?: string;
  extraClassName?: string;
}) => (
  <span
    onClick={onClick}
    key={key}
    className={`group mx-1 mt-2 inline-flex cursor-pointer items-center rounded-full bg-curious-blue-100 py-0.5 pl-2.5 pr-1 font-medium text-curious-blue-700 text-sm ${extraClassName}`}
  >
    {children}
    <button
      type="button"
      className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-curious-blue-400 focus:bg-curious-blue-500 focus:text-white focus:outline-none group-hover:bg-curious-blue-300 group-hover:text-curious-blue-500"
    >
      <span className="sr-only">Remove filter{removeLabelExt}</span>
      <svg
        className="h-2 w-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>
);
