import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { Rectangle, useMap, useMapEvents } from "react-leaflet";
import * as L from "leaflet";

function calcBounds(
  map: L.Map,
  padding: [number, number, number, number],
  margin = [0, 0, 0, 0],
) {
  const pBounds = map.getPixelBounds();
  const minNew = pBounds.min!.add([
    padding[3] + margin[3],
    padding[0] + margin[0],
  ]);
  const maxNew = pBounds.max!.subtract([
    padding[1] + margin[1],
    padding[2] + margin[2],
  ]);
  return L.latLngBounds(map.unproject(minNew), map.unproject(maxNew));
}

//function boundsChangeHandler(_e: L.LeafletEvent) {}

export const GeofilterProvider = observer(function GeofilterProvider({
  setFilterBounds,
  padding = [0, 0, 0, 0],
  showRectangle = false,
}: {
  setFilterBounds: (bounds: L.LatLngBounds, zoom?: number) => void;
  padding?: [number, number, number, number];
  showRectangle?: boolean;
}) {
  const map = useMap();
  useEffect(
    () => setFilterBounds(calcBounds(map, padding), map.getZoom()),
    [map, padding, setFilterBounds],
  ); // do not add padding to deps, otherwise it will be recalculated every time

  useMapEvents({
    zoomend(_e: L.LeafletEvent) {
      setFilterBounds(calcBounds(map, padding), map.getZoom());
    },
    resize(_e: L.LeafletEvent) {
      setFilterBounds(calcBounds(map, padding), map.getZoom());
    },
    moveend(_e: L.LeafletEvent) {
      setFilterBounds(calcBounds(map, padding), map.getZoom());
    },
  });
  if (showRectangle)
    return (
      <Rectangle
        bounds={calcBounds(map, padding, [4, 4, 4, 4])}
        fill={false}
        weight={3}
        color="currentcolor"
        dashArray="8 8 3 8"
        pane="markerPane"
        className="text-punch-500 m-0.5"
      />
    );
  return null;
});
