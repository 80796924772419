import { Menu, Transition } from "@headlessui/react";
import { SortDescendingIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";

export const SortButton = observer(function SortButton({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class="shadow-xl focus-within:shadow-2xl">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                aria-label="Sort"
                class={`ml-1 h-9 w-9 rounded border-0 bg-white bg-opacity-75 p-1 text-gray-600 shadow backdrop-blur-sm transition-all duration-300 hover:bg-opacity-90 hover:shadow-2xl dark:bg-black dark:bg-opacity-75 dark:text-white ${
                  open ? "ring" : ""
                } ${
                  typeof store.meili.currentSortOption.value != "undefined"
                    ? "bg-curious-blue-400 dark:bg-curious-blue-600"
                    : "bg-white dark:bg-black"
                }`}
                title="Change sort order"
              >
                <SortDescendingIcon
                  class="h-full w-full"
                  onClick={() => store.closeFilterPanel()}
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-aboveallcontent mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white bg-opacity-75 shadow-lg ring-1 ring-black ring-opacity-5 backdrop-blur-sm focus:outline-none dark:bg-black dark:bg-opacity-75 dark:text-white">
                <div className="px-1 py-1 ">
                  <span class={"m-2 inline-block"}>Sort by:</span>
                  {store.meili.sortOptions.map((option, index) => (
                    <Menu.Item
                      key={index}
                      onClick={() => store.meili.setCurrentSort(option.value)}
                    >
                      {({ active }) => {
                        const current =
                          store.meili.currentSortOption.value == option.value;
                        const activeOrCurrent = active || current;
                        return (
                          <button
                            className={`${
                              activeOrCurrent
                                ? "bg-violet-500 bg-curious-blue-500 text-white"
                                : "text-gray-900 dark:text-gray-200"
                            } ${
                              current
                                ? "xbg-violet-500 texxt-white"
                                : "txext-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {option.label}
                          </button>
                        );
                      }}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
});
