import copy from "copy-text-to-clipboard";
import toast from "react-hot-toast";
import { LinkDuo } from "components/svgs";

export function CopyToClipboardButton({
  classNames = "",
}: {
  classNames?: string;
}) {
  return (
    <a
      href={window.location.href}
      target="_blank"
      rel="noreferrer"
      class={`${classNames} inline-block rounded p-2`}
      title="Copy link to clipboard"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(window.location.href)
            .then(() => toast.success("The URL was copied to the clipboard!"))
            .catch(() =>
              toast.error(
                "Error copying to the clipboard. Please copy the URL from the browser location bar. Sorry for the inconvenience.",
              ),
            );
          return;
        }
        copy(window.location.href);
        toast.success("The URL was copied to the clipboard!");
      }}
    >
      <LinkDuo />
    </a>
  );
}
