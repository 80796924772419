import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AttributionControl,
  MapContainer,
  Marker,
  Rectangle,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvent,
  ZoomControl,
} from "react-leaflet";
import { useEventHandlers } from "@react-leaflet/core";
import { LatLngExpression } from "leaflet";
import { observer } from "mobx-react-lite";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const BOUNDS_STYLE = { weight: 2 };

function MinimapBounds({
  parentMap,
  zoom,
}: {
  parentMap: L.Map;
  zoom: number;
}) {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap],
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(
    () => ({ move: onChange, zoom: onChange }),
    [onChange],
  );
  useEventHandlers({ instance: parentMap }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

export function MinimapControl({
  position,
  zoom,
  center,
}: {
  position?: keyof typeof POSITION_CLASSES;
  zoom?: number;
  center: LatLngExpression;
}) {
  const parentMap = useMap();
  const mapZoom = zoom || 0;
  const [map, setMap] = useState<L.Map>();
  useEffect(() => {
    // Hack because since react-leaflet 4 or leaflet 1.8, the map didn't draw correctly
    map?.invalidateSize();
  }, [map]);

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        style={{ height: 80, width: 80 }}
        center={center ?? parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        ref={setMap}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    [center, mapZoom, parentMap],
  );

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  );
}

const UpdateCenterOnMarkerChange = observer(
  function UpdateCenterOnMarkerChange({
    loc,
  }: {
    loc: { lat: number; lng: number };
  }) {
    const map = useMap();
    map.panTo(loc);
    return null;
  },
);

export const MiniMap = observer(function MiniMap({
  loc,
  tooltipSuffix,
}: {
  loc: { lat: number; lng: number };
  tooltipSuffix: string;
}) {
  const [map, setMap] = useState<L.Map>();
  useEffect(() => {
    // Hack because since react-leaflet 4 or leaflet 1.8, the map didn't draw correctly
    map?.invalidateSize();
  }, [map]);
  if (!loc) return;
  return (
    <MapContainer
      id="PostMiniMap"
      center={loc}
      zoom={14}
      maxZoom={18}
      minZoom={5}
      scrollWheelZoom={false}
      ref={setMap}
      placeholder={
        <div>
          Location: {loc?.lat}, {loc?.lng}
        </div>
      }
      className="h-96 max-h-[50vh] w-full max-w-screen-lg"
      zoomControl={false}
      attributionControl={false}
      // style={{ width: "600px", height: "300px" }}
    >
      <TileLayer
        attribution='&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {loc && (
        <Marker position={loc}>
          {/* <Popup></Popup> */}
          <Tooltip direction="bottom" offset={[-15, 40]} permanent>
            {loc.lat}, {loc.lng}
            <br />
            {tooltipSuffix}
          </Tooltip>
        </Marker>
      )}
      <MinimapControl zoom={2} center={loc} />
      <AttributionControl
        position="bottomleft"
        prefix={`<a target="_blank" href="https://www.mappingmediafreedom.org/imprint/">Imprint</a>`}
      />
      <ZoomControl position="bottomleft" />
      <UpdateCenterOnMarkerChange loc={loc} />
    </MapContainer>
  );
});
