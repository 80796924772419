import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  AlignJustifyDuo,
  ChartBarDuo,
  GlobeEuropeDuo,
  SearchDuo,
} from "components/svgs";

export const BottomNav = observer(function ({
  homeActive = false,
  filterActive = false,
  listActive = false,
  chartActive = false,
  //moreActive = false,
  onHomeClick,
  onFilterClick,
  onListClick,
  onChartClick,
}: //onMoreClick,
{
  homeActive: boolean;
  filterActive: boolean;
  listActive: boolean;
  chartActive: boolean;
  moreActive: boolean;
  onHomeClick: () => void;
  onFilterClick: () => void;
  onListClick: () => void;
  onChartClick: () => void;
  onMoreClick: () => void;
}) {
  useEffect(() => {
    document.documentElement.classList.add("with-footer-nav");
    return () => {
      document.documentElement.classList.remove("with-footer-nav");
    };
  });
  return (
    <div class="pointer-events-auto inset-x-0 bottom-0 z-abovemap w-full bg-white bg-opacity-75 shadow-xl backdrop-blur-sm dark:bg-black dark:bg-opacity-75  dark:text-white">
      <div id="tabs" class="flex justify-between">
        <a
          href="#"
          onClick={onHomeClick}
          class={`inline-block w-full justify-center pt-2 pb-1 text-center text-xs hover:text-curious-blue-500 ${
            homeActive ? "text-curious-blue-500" : ""
          }`}
        >
          <GlobeEuropeDuo className={"mb-1 inline-block max-h-6"} />
          <div>Home</div>
        </a>
        <a
          href="#"
          onClick={onFilterClick}
          class={`inline-block w-full justify-center pt-2 pb-1 text-center text-xs hover:text-curious-blue-500 ${
            filterActive ? "text-curious-blue-500" : ""
          }`}
        >
          <SearchDuo className={"mb-1 inline-block max-h-6"} />
          <div>Filter</div>
        </a>
        <a
          href="#"
          onClick={onListClick}
          class={`inline-block w-full justify-center pt-2 pb-1 text-center text-xs hover:text-curious-blue-500 ${
            listActive ? "text-curious-blue-500" : ""
          }`}
        >
          <AlignJustifyDuo className={"mb-1 inline-block max-h-6"} />
          <div>List</div>
        </a>
        <a
          href="#"
          onClick={onChartClick}
          class={`inline-block w-full justify-center pt-2 pb-1 text-center text-xs hover:text-curious-blue-500 ${
            chartActive ? "text-curious-blue-500" : ""
          }`}
        >
          <ChartBarDuo className={"mb-1 inline-block max-h-6"} />
          <div>Charts</div>
        </a>
      </div>
    </div>
  );
});
