import { IReactionPublic } from "mobx";

export function debounceEffect<T>(
  effect: (arg: T, previousValue: T, r: IReactionPublic) => void,
  debounceMs: number,
) {
  let timer: NodeJS.Timeout;
  return (arg: T, previousValue: T, r: IReactionPublic) => {
    clearTimeout(timer);
    timer = setTimeout(() => effect(arg, previousValue, r), debounceMs);
  };
}
