import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { PreviewAlert } from "state/alert";
import { ExplorerStore } from "./store";
import { SortButton } from "components/sort-button";
import { ResultSummary } from "components/result-summary";
import { GeofilterButton } from "components/geofilter-button";
import { XIcon } from "@heroicons/react/outline";

const HighlightedText = ({ text }: { text: string }) => (
  <span
    class="highlighted"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  />
);

export const ListHeader = observer(function ListHeader({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class={"pointer-events-auto flex flex-row pr-2 pt-2 md:pl-10"}>
      <div
        class={
          "pointer-events-auto block h-9 w-full overflow-y-auto rounded bg-white bg-opacity-75 p-2 shadow-xl backdrop-blur-sm transition-all duration-500 ease-in-out text-sm dark:bg-black dark:bg-opacity-75 dark:text-white"
        }
      >
        <ResultSummary store={store} />
      </div>
      <GeofilterButton store={store} />
      <SortButton store={store} />
    </div>
  );
});

class ScrollSettings {
  overscan = 1000 + window.innerHeight;
  increaseViewportBy = 1000 + window.innerHeight;
}
const aeScrollSettings = new ScrollSettings();
(window as any).aeScrollSettings = aeScrollSettings;
export const AlertList = observer(function AlertList({
  posts = [],
  store,
  openFull,
  hover,
  className,
}: {
  posts: PreviewAlert[];
  store: ExplorerStore;
  className: string;
  openFull: (id: number) => void;
  hover: (alert?: PreviewAlert) => void;
}) {
  const virtuosoRef = useCallback(
    (node: VirtuosoHandle) => store.setVirtuoso(node),
    [store],
  );

  return (
    <Virtuoso
      className={`relative flex flex-col pb-4 pt-4 md:-ml-10 ${className}`}
      components={{
        Header: () => {
          return <ListHeader store={store} />;
        },
      }}
      data={posts}
      ref={virtuosoRef}
      // increaseViewportBy={400}
      overscan={aeScrollSettings.overscan} /* pixels, not items */
      increaseViewportBy={
        aeScrollSettings.increaseViewportBy
      } /* pixels, not items */
      endReached={() => store.meili.updatePreviewList(posts.length)}
      itemContent={(index) => (
        <div class="pointer-events-none py-2.5 pr-2 md:pl-10">
          <PostPreview post={posts[index]} openFull={openFull} hover={hover} />
        </div>
      )}
    />
  );
});

export const PostPreview = observer(function PostPreview({
  post,
  openFull,
  hover,
}: {
  post: PreviewAlert;
  openFull: (id: number) => void;
  hover: (alert?: PreviewAlert) => void;
}) {
  return (
    <a
      href={post.url}
      class="group pointer-events-auto relative block cursor-pointer overflow-hidden rounded-lg bg-white bg-opacity-75 p-2 shadow-xl backdrop-blur-sm transition-all duration-500 ease-in-out hover:z-10 hover:bg-opacity-100 hover:shadow-2xl dark:bg-black dark:bg-opacity-75 dark:text-white md:hover:scale-101"
      onClick={(e) => {
        e.preventDefault();
        openFull(post.id);
      }}
      onMouseEnter={() => {
        hover(post);
      }}
      onMouseLeave={() => hover()}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          store.meili.togglefilter(`exclude_id`, post.id);
        }}
        className="absolute right-1 top-1 hidden group-hover:block cursor-pointer rounded border border-transparent hover:border-punch-400 transition-all flex items-center"
        title={`Exclude this alert`}
      >
        <XIcon class="h-4 w-4" />
      </div>
      <small>
        <span>
          <strong>
            <HighlightedText text={post.date} />
          </strong>
        </span>
        {" | "}
        <span>
          <strong>
            <HighlightedText text={post.country} />
          </strong>
        </span>
        {" | "}
        <span>
          Type:{" "}
          <strong>
            <HighlightedText text={post.type_of_incident.join(", ")} />
          </strong>
        </span>
        {false && post.attacked_count > 0 && (
          <>
            {" | "}
            <span>
              Attacked journalists:{" "}
              <strong>
                <HighlightedText text={post.attacked_count.toString()} />
              </strong>
            </span>
          </>
        )}
      </small>
      <h3 class="text-steel-blue-500 text-lg dark:text-steel-blue-300">
        <HighlightedText text={post.title} />
      </h3>
      <p>
        <HighlightedText text={post.content} />
      </p>
    </a>
  );
});
