import { airbrake } from "utils/airbrake";
import { toast } from "react-hot-toast";

export function errorMessageWithReload(
  message: string,
  internalMessage: string,
  additionalData?: any,
  showDelay = 2000,
  reloadDelay = 10000,
) {
  console.error("Error:", internalMessage);
  airbrake.notify({
    error: new Error(internalMessage),
    params: {
      additionalData,
    },
  });
  setTimeout(() => {
    toast(message, {
      duration: Infinity,
      icon: "🛑",
      style: {
        borderRadius: "0.5rem",
        backgroundColor: "#FFD2D2",
      },
    });
    toast("This page will be reloaded in a few seconds to try again.", {
      duration: Infinity,
      icon: "ℹ️",
      style: {
        borderRadius: "0.5rem",
      },
    });
  }, showDelay);
  setTimeout(() => {
    window.location.reload();
  }, reloadDelay);
}
