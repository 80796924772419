import * as React from "react";
import { useRef } from "react";
import { observer } from "mobx-react-lite";
import { LayerGroup, LayersControl } from "react-leaflet";
import { ExplorerStore } from "explorer/store";
import * as L from "leaflet";

export const Clusters = observer(function Clusters({
  store,
}: {
  store: ExplorerStore;
}) {
  const clusterLayer = useRef<L.MarkerClusterGroup>(null);
  return (
    <LayersControl.Overlay checked={true} name="Clusters by Region">
      <LayerGroup
        ref={clusterLayer}
        eventHandlers={{
          add: () =>
            clusterLayer.current?.addLayer(store.markerGroup.marker_group),
          remove: () =>
            clusterLayer.current?.removeLayer(store.markerGroup.marker_group),
        }}
      />
    </LayersControl.Overlay>
  );
});
