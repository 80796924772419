//import copy from "copy-text-to-clipboard";
//import toast from "react-hot-toast";
//import { LinkIcon } from "@heroicons/react/outline";
import { AlignJustifyDuo, ChartBarDuo, QuestionDuo } from "components/svgs";

// Leaflet 1.7.1 causes 2 click events to be emitted by Leaflet Controls in Safari
// https://github.com/Leaflet/Leaflet/issues/7255 -- closed but still happening in Leaflet master as of 2022-01-28

// for debugging:
// JSON.safeStringify = (obj, indent = 2) => {
//   let cache = [];
//   const retVal = JSON.stringify(
//     obj,
//     (key, value) =>
//       typeof value === "object" && value !== null
//         ? cache.includes(value)
//           ? undefined // Duplicate reference found, discard key
//           : cache.push(value) && value // Store value in our collection
//         : value,
//     indent
//   );
//   cache = null;
//   return retVal;
// };
//
// -> the first event has "_simulated": true in its properties
// however, I decided not to skip _simulated events as they may be needed on some devices

//
// temporary solution: wrap in a "preventSimulatedEvent

let recentlyTriggered = false;
export function preventSimulatedEventonIOS(cb: any) {
  if (!recentlyTriggered) {
    cb();
    recentlyTriggered = true;
    setTimeout(() => {
      recentlyTriggered = false;
    }, 300);
  }
}

export function HelpButton({
  classNames = "",
  label = true,
  onClick,
}: {
  classNames?: string;
  label?: boolean;
  onClick: () => void;
}) {
  return (
    <a
      class={`${classNames} inline-flex touch-none rounded p-1`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        preventSimulatedEventonIOS(onClick);
        // onClick();
      }}
      title="Help"
    >
      <QuestionDuo className={"mb-1 inline-block max-h-6"} />
      {label ? "Help" : null}
    </a>
  );
}
export function ToggleListButton({
  classNames = "",
  label = true,
  onClick,
}: {
  classNames?: string;
  label?: boolean;
  onClick: () => void;
}) {
  return (
    <a
      class={`${classNames} inline-flex rounded p-1`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        preventSimulatedEventonIOS(onClick);
        // onClick();
      }}
      title="List"
    >
      <AlignJustifyDuo className={"mb-1 inline-block max-h-6"} />
      {label ? "List" : null}
    </a>
  );
}

export function ToggleChartsButton({
  classNames = "",
  label = true,
  onClick,
}: {
  classNames?: string;
  label?: boolean;
  onClick: () => void;
}) {
  return (
    <a
      class={`${classNames} inline-flex rounded p-1`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        preventSimulatedEventonIOS(onClick);
        // onClick();
      }}
      title="Charts"
    >
      <ChartBarDuo className={"mb-1 inline-block max-h-6"} />
      {label ? "Charts" : null}
    </a>
  );
}
