import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { ExplorerStore } from "explorer/store";

export const GeofilterButton = observer(function GeofilterButton({
  store,
}: {
  store: ExplorerStore;
}) {
  return (
    <div class="shadow-xl focus-within:shadow-2xl">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                aria-label="Filter by visible map area"
                class={`ml-1 h-9 w-9 rounded border-0 bg-opacity-75 p-1 text-gray-600 shadow backdrop-blur-sm transition-all duration-300 hover:bg-opacity-90 hover:shadow-2xl dark:bg-black dark:bg-opacity-75 dark:text-white ${
                  open ? "ring" : ""
                } ${
                  store.meili.isPreviewListGeofiltered
                    ? "bg-curious-blue-400 dark:bg-curious-blue-600"
                    : "bg-white dark:bg-black"
                }`}
                title="Change shown previews"
              >
                {/* Font awesome 5 draw-square regular */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="h-full w-full"
                  onClick={() => store.closeFilterPanel()}
                >
                  <path
                    fill="currentColor"
                    d="M408 356.75v-201.5c23.44-9.5 40-32.41 40-59.25 0-35.35-28.65-64-64-64-26.84 0-49.75 16.56-59.25 40h-201.5c-9.5-23.44-32.4-40-59.25-40C28.65 32 0 60.65 0 96c0 26.84 16.56 49.75 40 59.25v201.49C16.56 366.25 0 389.15 0 416c0 35.35 28.65 64 64 64 26.85 0 49.75-16.56 59.25-40h201.49c9.5 23.44 32.41 40 59.25 40 35.35 0 64-28.65 64-64 .01-26.85-16.55-49.75-39.99-59.25zm-320 0v-201.5A64.053 64.053 0 0 0 123.25 120h201.49a64.053 64.053 0 0 0 35.25 35.25v201.49a64.053 64.053 0 0 0-35.25 35.25H123.25A64.066 64.066 0 0 0 88 356.75zM384 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zm0 352c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm320 0c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z"
                  />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-aboveallcontent mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white bg-opacity-75 shadow-lg ring-1 ring-black ring-opacity-5 backdrop-blur-sm focus:outline-none dark:bg-black dark:bg-opacity-75 dark:text-white">
                <div className="px-1 py-1 ">
                  <span class={"m-2 inline-block"}>Show in list:</span>
                  {store.meili.listOptions.map((option, index) => (
                    <Menu.Item
                      key={index}
                      onClick={() =>
                        store.meili.setCurrentListOption(option.value)
                      }
                    >
                      {({ active }) => {
                        const current =
                          store.meili.currentListOption.value == option.value;
                        const activeOrCurrent = active || current;
                        return (
                          <button
                            className={`${
                              activeOrCurrent
                                ? "bg-violet-500 bg-curious-blue-500 text-white"
                                : "text-gray-900 dark:text-gray-200"
                            } ${
                              current
                                ? "xbg-violet-500 texxt-white"
                                : "txext-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {option.label}
                          </button>
                        );
                      }}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
});
