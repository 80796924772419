type LabelCountPair = [string, number];
export const sortAlphabeticalAsc = (a: LabelCountPair, b: LabelCountPair) => {
  if (a[0] < b[0]) {
    return -1;
  }
  if (a[0] > b[0]) {
    return 1;
  }
  return 0;
};

export const sortCountDesc = (a: LabelCountPair, b: LabelCountPair) => {
  if (a[1] < b[1]) {
    return 1;
  }
  if (a[1] > b[1]) {
    return -1;
  }
  return 0;
};

export const generateListIndexSort = (list: string[]) => {
  return (a: LabelCountPair, b: LabelCountPair) => {
    if (list.indexOf(a[0]) < list.indexOf(b[0])) {
      return -1;
    }
    if (list.indexOf(a[0]) > list.indexOf(b[0])) {
      return 1;
    }
    return 0;
  };
};

export const reverse = (comparer: (a: any, b: any) => number) => {
  return (a: unknown, b: unknown) => comparer(b, a);
};

export const mainToIComparer = generateListIndexSort([
  "Physical",
  "Verbal",
  "Property",
  "Legal",
  "Censorship",
  "no data collected",
]);
export const euMembershipComparer = generateListIndexSort([
  "EU Member States",
  "EU candidate countries",
  "EU potential candidate countries",
  "Non-EU countries",

  "EU Member States (on date of incident)",
  "EU candidate countries (on date of incident)",
  "EU potential candidate countries (on date of incident)",
  "Non-EU countries (on date of incident)",
]);
